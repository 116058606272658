import axios from 'axios'

export default {

    async getUserInfo(userId: string) {

        return new Promise((success, die) => {
            axios.get(`/users/${userId}`).then(response => {
                success(response ? response.data : null)
            }).catch(die)
        })
    },
}