<template>
  <v-app id="app">
    <v-navigation-drawer disable-resize-watcher color="#4A4949" class="side-nav" fixed v-model="showSideNav" app
                         width="285">
      <v-list>
        <h3 style="padding: 1em">Bonjour {{ $keycloak.tokenParsed.name }}</h3>
        <v-list-item v-for="(item, index) in menuItems" :key="index">
          <v-list-item-title class="link" @click="$keycloak.logoutFn()">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="black" dark>
      <v-app-bar-nav-icon v-if="$isMobile()" class="hamburger-menu" aria-label="MENU" @click="toggleSideNav" dark/>

      <router-link to="/" class="app-title">
        <img v-if="$isMobile()" src="/logo_white_mobile.png" alt="Drive Pièces Auto"/>
        <img v-else src="/logo_city_white.png" height="44" width="380" alt="Drive Pièces Auto"/>
      </router-link>

      <v-spacer></v-spacer>

      <div class="right-bar">
        <div class="app-status">
          API
          <span class="app-up" v-if="isApiUp">OK</span>
          <span class="app-down" v-else else>KO</span>

        </div>
        <v-menu v-if="!$isMobile()" offset-y offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="isAdmin" large v-on="on" v-bind="attrs">
              mdi-account-circle-outline
            </v-icon>
          </template>

          <v-list>

            <h3 style="padding: 1em">Bonjour {{ $keycloak.tokenParsed.name }}</h3>
            <v-list-item v-for="(item, index) in menuItems" :key="index">
              <v-list-item-title class="link" @click="item.action">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>


      <template v-if="isAdmin" v-slot:extension>
        <v-tabs :align-with-title="!$isMobile()">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab link to="/">Dashboard</v-tab>
          <v-tab link to="/orders">Commandes</v-tab>
          <v-tab link to="/expeditions">Expéditions</v-tab>
          <v-tab link to="/returns">Retours</v-tab>
          <v-tab link to="/vouchers">Vouchers</v-tab>
          <v-tab link href="https://analytics.drivepiecesauto.com" target="_blank">Stats</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main class="app-content">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import {HealthService} from "@/api/index.ts";
import moment from "moment-timezone";

export default {
  components: {},

  data() {

    return {
      isApiUp: false,
      showSideNav: false,
      healthServiceIntervalId: null,
      menuItems: [
        {title: 'Paramètres', action: () => this.$router.push('/settings')},
        {title: 'Se déconnecter', action: () => this.$keycloak.logoutFn()},
      ]
    }
  },
  methods: {
    async isUp() {
      this.isApiUp = await HealthService.isUp()
    },
    toggleSideNav() {
      this.showSideNav = !this.showSideNav
    }
  },
  computed: {
    isAdmin() {
      return this.$keycloak.hasRealmRole("mpa_admin")
    },

  },
  created() {

    let role = this.$keycloak.hasRealmRole("mpa_admin")
    if (!role) {
      this.$router.replace("/denied")
    } else {
      this.$store.dispatch("orders/subscribeToNewOrder")

      this.isUp()
      this.healthServiceIntervalId = window.setInterval(this.isUp, 60000);
    }
  },
  mounted() {
    const sixMonthAgo = moment().subtract(6, 'months').format("YYYY-MM-DD");
    this.$store.dispatch("orders/getOrders", {
      after: sixMonthAgo
    })
    this.$store.dispatch("returns/fetchReturns", ["CREATED", "IN_PROGRESS"])
  },
  beforeDestroy() {
    window.clearInterval(this.healthServiceIntervalId)
  }
};
</script>

<style lang="scss" scoped>
@import 'styles/colors';

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.app-title {

  display: flex;
  align-items: center;

  .subtitle {
    color: white;
    text-decoration: none !important;
  }

  .brand-name {
    color: $primary-color;
    font-size: xx-large;
  }

  .app-name {
    height: 100%;
    font-size: xx-large;
    margin-left: 0.3em;
  }

  img {

  }
}

.right-bar {

  display: flex;
  align-items: center;


  .app-status {
    margin-right: 1em;
    font-weight: bold;
  }

  .app-up {
    color: $green-darker;
  }

  .app-down {
    color: $red
  }
}

@media only screen and (max-width: 1000px) {

  .side-nav {
    background-color: pink;
  }
  .app-title {

    img {
      height: 26px;
    }
  }


}


</style>
