<template>
  <div class="page">
    <div class="content-panel">
      <div :class="contentClass">
        <div class="orders-wrapper right-panel">

          <Block no-padded no-header no-footer>
            <div class="search-box">
              <v-text-field
                  class="filter-input"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  flat
                  aria-autocomplete="none"
                  label="Rechercher une facture"
                  single-line
                  hide-details
              ></v-text-field>

              <v-select solo flat class="filter-status"
                        :items="orderStatuses"
                        item-text="label"
                        hide-details
                        v-model="filterStatus"
                        @change="filterByStatus"
                        item-value="key" label="Filtrer par status">
                <template v-slot:selection="{ item }">
                <span class="d-flex flex-end" style="width: 100%; display: flex; justify-content: flex-end">
                  {{ item.label }}
                </span>
                </template>
              </v-select>
            </div>

          </Block>


          <Block no-padded>
            <template slot="header-action">
              <span v-if="errorMessage" class="error--text" style="margin-right: 1em">{{errorMessage}}</span>
              <v-btn v-if="actionButton && !showEditPanel && actionButton.length === 1"
                     color="primary" text
                     :loading="actionLoading"
                     @click="executeAction(actionButton[0])"
                     :disabled="isActionDisabled">
                {{ actionButton[0].text }}
              </v-btn>

              <v-menu v-else-if="actionButton && !showEditPanel && actionButton.length > 1" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" text
                      v-bind="attrs" :loading="actionLoading"
                      v-on="on">
                    Actions <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in actionButton"
                               :key="index" link :disabled="isActionDisabled" @click="executeAction(item)">
                    <v-list-item-action>{{ item.text }}</v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-menu>

            </template>
            <v-data-table :items="filteredOrders"
                          :headers="headers"
                          :search="search"
                          v-model="selectedOrders"
                          item-key="orderId"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :show-select="!showEditPanel"
                          no-data-text="Vous n'avez aucune commande"
                          @click:row="handleClick">


              <template v-slot:[`item.orderId`]="{ item }">
                {{ item.orderId.substring(0, 8).toUpperCase() }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <div style="display: flex; align-items: center; justify-content: center">

                <v-icon v-if="item.isInError">mdi-alert</v-icon>
                <StatusTag v-else :value="item.status"/>
                </div>
              </template>
              <template v-slot:[`item.nbArticles`]="{ item }">
                {{ item.items.length }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                {{ item.paymentInfo ? `${item.price} €` : "" }}
              </template>
              <template v-slot:[`item.clientName`]="{ item }">
                {{ item.billingAddress ? `${item.billingAddress.recipient}` : "" }}
              </template>
              <template v-slot:[`item.creationDate`]="{ item }">
                {{ $formatDate(item.creationDate, 'Do MMMM YYYY HH:mm') }}
              </template>
            </v-data-table>
          </Block>
        </div>

        <div :class="editPanelClass">
          <router-view :key="$route.path" name="customer"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import StatusTag from "../../components/StatusTag.vue";
import {mapState} from "vuex";
import Block from "../../components/SimpleBlock.vue";

export default {

  components: {StatusTag, Block},
  data() {
    return {
      filterStatus: null,
      search: null,
      selectedOrders: [],
      ordersInError: [],
      sortBy: 'creationDate',
      sortDesc: true,
      fullHeaders: [
        {
          text: 'N° Commande',
          value: 'orderId',
        },
        {text: 'Client', value: 'clientName'},
        {text: "Nb articles", value: 'nbArticles'},
        {text: 'Prix', value: 'price'},
        {text: 'Ref. WinPro', value: 'externalId'},
        {text: 'Date de la commande', value: 'creationDate'},
        {text: 'Status', value: 'status'}
      ],
      showEditPanel: false,
      actionLoading: false,
      errorMessage: null,
      successMessage: null
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state.session.user
      },
      orders(state) {
        return state.orders.orders.map(order => {
          return {
            ...order,
            //FIXME should it be user name ?
            clientName: order.billingAddress ? order.billingAddress.recipient : "",
            price: order.paymentInfo ? order.paymentInfo.totalTTC.toFixed(2) : "",
            isInError: this.ordersInError.filter(o => o.orderId === order.orderId).length === 1
          }
        })
      },
    }),
    isActionDisabled() {
      return this.selectedOrders.length === 0
    },
    actionButton() {

      switch (this.filterStatus) {
        case 'PAID':
          return [{
            text: "Ajouter les commandes à WinPro",
            action: "ADD_WINPRO_ORDER",
            execute: this.placeWinProOrder
          }]
        case 'PLACED':
          return [{
            text: "Valider les commandes",
            action: "ORDERED_PRODUCTS",
            execute: this.changeStatusBatch
          }]
        case 'ORDERED':
          return [{
            text: "Valider les préparations",
            action: "ORDER_PREPARED",
            execute: this.changeStatusBatch
          }]
        case 'TO_PREPARE':
          return [{
            text: "Valider les préparations",
            action: "ORDER_PREPARED",
            execute: this.changeStatusBatch
          }]
        case 'TO_DISPATCH':
          return [{
            text: "Remis au transporteur",
            action: "ORDER_GIVEN_TO_CARRIER",
            execute: this.changeStatusBatch
          }]
        case 'AVAILABLE':
          return [{
            text: "Finaliser les commandes",
            nextStatus: "DONE",
            execute: this.changeStatusBatch
          }]
        default:
          return null
      }
    },
    orderStatuses() {
      return this.$store.getters['orders/getFilterStatuses']
    },
    filteredOrders() {
      switch (this.filterStatus) {
        case null:
        case undefined:
          return this.orders.filter(o => o.status !== 'DONE')
        case 'ALL':
          return this.orders
        case 'IN_ERROR':
          return this.orders.filter(o => ["ERROR", "CREATED", "PAID"].indexOf(o.status) !== -1)
        default:
          return this.orders.filter(o => o.status === this.filterStatus)
      }
    },
    editPanelClass() {
      if (this.showEditPanel) {
        return "edit-panel open"
      } else return "edit-panel"
    },
    contentClass() {
      if (this.showEditPanel) {
        return "panel-content open"
      } else return "panel-content"
    },
    headers() {
      return this.showEditPanel
          ? this.fullHeaders.filter(h => ['orderId', 'clientName'].indexOf(h.value) !== -1)
          : this.fullHeaders
    },
  },
  watch: {
    async search(val) {
      // if order number
      if (val && val.length >= 8 && val.indexOf(" ") === -1) {
        let respone = await this.$store.dispatch("orders/searchForTerminatedOrders", val)
        if (respone.length > 0) {
          if (this.orders.findIndex(o => o.orderId === respone[0].orderId) === -1) this.orders.push(respone[0])
          this.filterStatus = "ALL"
        }
      }
    }
  },
  methods: {

    async changeStatusBatch(action) {

      try {

        let responses = await this.$store.dispatch("orders/executeBatchAction", {
          payload: this.selectedOrders.map(o => ({
            orderId: o.orderId,
            userId: o.userId
          })),
          action: action
        })

        this.ordersInError = responses?.filter((r) => r.error) ?? []
        if (this.ordersInError.length > 0) this.errorMessage = "Certaines commandes sont en erreur"

      } catch (e) {
        this.errorMessage = e
      }
    },

    placeWinProOrder() {
      return this.$store.dispatch("orders/createWinProOrders", this.selectedOrders)
    },

    async executeAction(action) {

      try {
        this.actionLoading = true
        this.ordersInError = []

        await action.execute(action.action)

      } catch (e) {
        this.errorMessage = e
      } finally {
        this.actionLoading = false
      }
    },
    filterByStatus(status) {
      this.selectedOrders = []
      this.$router.push(`/orders?status=${status}`);
    },
    handleClick(value) {
      this.errorMessage = null
      if (this.showEditPanel) {
        this.$router.replace(`/orders/${value.orderId}/${value.userId}`);
      } else {
        this.$router.push(`/orders/${value.orderId}/${value.userId}`);
      }
    },
    orderStatus(status) {
      switch (status) {
        case "CREATED":
          return "Crée"
        case "PLACED":
          return "En cours"
        case "ERROR":
          return "Erreur"
      }
    },
  },
  async created() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;

  },
  updated() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;

  },
  mounted() {
    this.filterStatus = this.$route.query.status
  }
}
</script>

<style lang="scss">
.v-select__selection {
  width: 100%;
  justify-content: flex-end;
}

.filter-status {
  flex: 0.4;

  .v-label {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>

@import "../../styles/ContentPanel";

.user-orders-wrapper {
  flex: 1;
}


@media only screen and (max-width: 1000px) {


}
</style>
