import axios from 'axios'
import { ArticleReturnsStatus } from 'shared-model'
import {UpdateReturnItemRequest} from "@/api/api-model";

export default {

    async delete(returnId: string) {
        try {
            const response = await axios.delete(`/returns/${returnId}`)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async updateItem({id, reference, status, warrantyId} : UpdateReturnItemRequest) {
        try {
            const response = await axios.put(`/returns/${id}/article/${reference}/status`, {status, warrantyId})
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    },

    async findAllReturns(statuses: ArticleReturnsStatus[]) {
        try {
            const url = "/returns";
            const params = new URLSearchParams();
            if (statuses) {
                for (let i = 0; i < statuses.length; i++) {
                    params.append("status", statuses[i]);
                }
            }
            const response = await axios.get(url, { params })
            return response ? response.data : null
        } catch (e: any) {
            throw 500
        }
    },

    async getById(id: string) {
        try {
            const response = await axios.get(`/returns/${id}`)
            return response ? response.data : null
        } catch (e) {
            throw 500
        }
    }
}
