<template>
    <div class="content-panel">
      <div :class="contentClass">
        <div class="returns-wrapper right-panel">

          <Block no-padded no-header no-footer>
            <div class="search-box">
              <v-text-field
                  class="filter-input"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  flat
                  aria-autocomplete="none"
                  label="Rechercher une demande de retour"
                  single-line
                  hide-details
              ></v-text-field>
            </div>
          </Block>

          <Block no-padded no-header>
            <v-data-table :items="returns"
                          :headers="headers"
                          :search="search"
                          item-key="id"
                          no-data-text="Il n'y aucune demande de retour"
                          @click:row="handleClick">
              <template v-slot:[`item.orderId`]="{ item }">
                {{ item.orderId.substring(0, 8).toUpperCase() }}
              </template>
              <template v-slot:[`item.creationDate`]="{ item }">
                {{
                  $formatDate(item.creationDate, 'Do MMMM YYYY')
                }}
              </template>
              <template v-slot:[`item.items`] ="{ item }">
                <div v-for="(returnItem, i) in item.items" v-bind:key="`reason-${item.id}-${i}`">
                  <return-reason-tag :value="returnItem.reason" />

                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <return-status-tag :value="item.status" />
              </template>
            </v-data-table>
          </Block>
        </div>
        <div :class="editPanelClass">
          <router-view :key="$route.path" />
        </div>
      </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Block from "../components/SimpleBlock.vue";
import ReturnStatusTag from "../components/ReturnStatusTag";
import ReturnReasonTag from "../components/ReturnReasonTag.vue";
import _ from 'lodash'

export default {
  components: {
    ReturnStatusTag,
    ReturnReasonTag,
    Block,
  },
  data() {
    return {
      showEditPanel: false,
      error: null,
      search: null,
      fullHeaders: [
        {
          text: 'N° Commande',
          value: 'orderId',
        },
        {text: 'Client', value: 'clientName'},
        {text: 'Téléphone', value: 'clientPhone'},
        {text: 'Motif', value: 'items'},
        {text: 'Date demande', value: 'creationDate'},
        {text: 'Status', value: 'status'}
      ],
    }
  },
  computed: {
    ...mapState({
      returns(state) {
        return _.orderBy(state.returns.returns, ['creationDate'], ['desc'])
      }
    }),
    editPanelClass() {
      if (this.showEditPanel) {
        return "edit-panel open"
      } else return "edit-panel"
    },
    contentClass() {
      if (this.showEditPanel) {
        return "panel-content open"
      } else return "panel-content"
    },
    headers() {
      return this.showEditPanel
          ? this.fullHeaders.filter(h => ['id', 'clientName'].indexOf(h.value) !== -1)
          : this.fullHeaders
    }
  },
  methods: {
    handleClick(value) {
      if (this.showEditPanel) {
        this.$router.replace(`/returns/${value.id}`);
      } else {
        this.$router.push(`/returns/${value.id}`);
      }
    },

  },
  async created() {
    this.showEditPanel = !!this.$route.params.id;

  },
  updated() {
    this.showEditPanel = !!this.$route.params.id;

  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/ContentPanel";

.no-data {
  text-align: center;
}

.returns-wrapper {
  flex: 1;
}

</style>
