<template>
  <span :class="tagClass">{{ $store.getters['orders/getStatusName'](status) }}</span>
</template>

<script>
export default {
  props: {
    value: String,
    large: Boolean,
    xLarge: Boolean
  },
  data() {
    return {
      status: this.value,
      tagsClass: {
        CREATED: {
          tagClass: "tag orange",
          text: "Crée"
        },
        PAID: {
          text: "En cours",
          tagClass: "tag orange"
        },
        PLACED: {
          text: "En cours",
          tagClass: "tag yellow"
        },
        ORDERED: {
          text: "En cours",
          tagClass: "tag yellow"
        },
        TO_PREPARE: {
          text: "A préparer",
          tagClass: "tag yellow"
        },
        GIVEN_TO_CARRIER: {
          text: "Remis au transporteur",
          tagClass: "tag yellow"
        },
        TO_DISPATCH: {
          text: "A expédier",
          tagClass: "tag yellow"
        },
        AVAILABLE: {
          text: "En cours",
          tagClass: "tag yellow"
        },
        DONE: {
          text: "En cours",
          tagClass: "tag green"
        },
        ERROR: {
          text: "En erreur",
          tagClass: "tag red"
        },
        CANCELLED: {
          text: "Annulée",
          tagClass: "tag orange"
        }
      }
    }
  },
  computed: {
    tagClass() {
      let clazz= ""
      let tag = this.tagsClass[this.status];
      if (!tag) clazz = "tag red"
      else clazz = tag.tagClass

      if (this.large) clazz += " large"
      if (this.xLarge) clazz += " x-large"
      return clazz
    },
    tagText() {
      let tag = this.tagsClass[this.status];
      return tag ? tag.text : null
    }
  },
  watch: {
    value(val) {
      this.status = val
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

.tag {
  font-weight: bold;
  font-size: medium;
  background-color: transparent !important;
}

.tag.large {
  font-size: large;
}

.tag.x-large {
  font-size: x-large;
}

.tag.red {
  color: $red;
  background-color: white !important;
}

.tag.green {
  color: $green;
}

.tag.yellow {
  color: $yellow;
}
.tag.orange {
  color: $orange;
}
</style>
