<template>
    <v-dialog v-model="showDialog" :max-width="width"
              :fullscreen="fullWidth" :persistent="persistent"
              @click:outside="$emit('agreed')">
        <v-card>
            <v-card-title class="headline">
              <slot name="title">
               {{title}}
              </slot>
            </v-card-title>
          <v-card-text>
            <slot name="content" />
          </v-card-text>
            <v-card-actions>
               <slot name="actions" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'SimpleDialog',
        props: {
            title: String,
            message: String,
            dialog: Boolean,
            maxWidth: String,
            persistent: Boolean,
          fullWidth: Boolean
        },
      data() {
          return {
            width: this.maxWidth ? this.maxWidth : 450,
            showDialog: this.dialog
          }
      },
      watch: {
          dialog(val) {
            this.showDialog = val
          }

      }
    }
</script>

<style lang="scss" scoped>
.headline {
  margin-bottom: 1em;
}
</style>