import { render, staticRenderFns } from "./OpeningHoursEditor.vue?vue&type=template&id=6af57786&scoped=true"
import script from "./OpeningHoursEditor.vue?vue&type=script&lang=ts"
export * from "./OpeningHoursEditor.vue?vue&type=script&lang=ts"
import style0 from "./OpeningHoursEditor.vue?vue&type=style&index=0&id=6af57786&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_qty7iymlffgpukvncpluvycwfu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af57786",
  null
  
)

export default component.exports