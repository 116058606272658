<template>
  <div class="page page-content">
    <div  class="stats">
      <h1>COMING SOON</h1>
    </div>
  </div>
</template>

<script>

export default {

}
</script>

<style scoped>
.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
  height: 500px;
}
</style>