import axios from 'axios'
import { Shop } from 'shared-model'

export default {

    async getById(shopId: string) {
        const response = await axios.get(`/shop/${shopId}`)
        return response.data
    },

    async add(shop: Shop) {

        try {

            const response = await axios.post(`/shop`, shop)
            return response.data

        } catch (e: any) {
            if (e.status.indexOf("409")) throw 409
            throw e
        }
    },

    async update(shop: Shop) {

        try {
            const response = await axios.put(`/shop/${shop.id}`, shop)
            return response.data
        } catch (e: any) {
            throw "Erreur lors de la sauvegarde"
        }
    }
}
