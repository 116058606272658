import moment from "moment";

export default {

    install(Vue) {
        Vue.prototype.$formatDate = function(date, format) {
            let cleanedDate = clean(date);
            return cleanedDate ? moment(cleanedDate).format(format) : ''
        }

        Vue.prototype.$cleanDate = clean
    }
}

function clean(date) {
    if ( date ) {
        return typeof date === 'string' || date instanceof String
            ? date.replaceAll("UTC", "Z")
            : date
    } else return null
}