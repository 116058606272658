<script>
import SimpleDialog from "@/components/SimpleDialog.vue";
import RefundOrderItemCartouche from "@/views/orders/components/RefundOrderItemCartouche.vue";
import _ from "lodash";

export default {
  name: "RefundOrderDialog",
  components: {RefundOrderItemCartouche, SimpleDialog },
  props: {
    order: Object,
    showDialog: Boolean,
    showRefundAlert: Boolean
  },
  data() {
    return {
      selectedItems: [],
      customAmountToRefund: null,
      errorMessage: null,
      loading: false,

    }
  },
  methods: {
    async refundOrder() {

      console.log("amountTorefund : " + JSON.stringify(this.amountToRefund))
      if (this.order?.paymentInfo?.provider === 'SOGECOM') {
        this.errorMessage = "Le remboursement n'est pas disponible pour les transactions SOGECOM"
      } else if (this.amountToRefund <= 0) {
        this.errorMessage = "Le montant à rembourser doit être supérieur à 0"
      } else if (this.amountToRefund > this.order?.paymentInfo?.totalTTC) {
        this.errorMessage = "Le montant à rembourser ne peut pas être supérieur au montant total de la commande"
      }



      // else if (this.displayConfirmation === false) {
      //   this.displayConfirmation = true
      //   this.confirmationMetadata = {
      //     title: "Remboursement de la commande",
      //     text: `Êtes vous sur de vouloir rembourser cette commande pour un total de ${this.order.paymentInfo.totalTTC.toFixed(2)} € ? Cette action est définitive !`,
      //     onClick: () => this.refundOrder()
      //   }
      // }
      else {

        try {
          this.errorMessage = null
          this.loading = true
          let response = await this.$store.dispatch("orders/refundOrder", {
            orderId: this.order.orderId,
            userId: this.order.userId,
            amountToRefund: this.amountToRefund
          })
          this.$emit("on-success", response)
          //this.confirmationMetadata = {}
        } catch (e) {
          this.errorMessage = e
        } finally {
          this.loading = false
        }
      }
    },
    cancel() {
      this.errorMessage = null
      this.customAmountToRefund = null
      this.$emit("on-cancel")
    },
    onItemUpdate(selectedItems) {
      this.selectedItems = selectedItems
    }
  },
  computed: {
    amountToRefund() {
      if (this.customAmountToRefund > 0) return this.customAmountToRefund
      else return _.round(this.selectedItems.reduce((acc, item) => {
          let toFixed = this.$unitPriceHT(item.product) * item.quantity
          return acc + toFixed
        }, 0) * 1.2, 2)
    }
  },
}
</script>

<template>
  <div>
    <SimpleDialog title="Rembourser une commande" :dialog="showDialog" max-width="900" :full-width="$isMobile()" persistent>
      <template v-slot:content>
        <h4>Sélectionnez les produits à rembourser ...</h4>
        <RefundOrderItemCartouche :items="order.items" @on-update="onItemUpdate"/>
        <v-text-field type="number" placeholder="Montant à rembourser" label="Ou saisissez un montant" v-model="customAmountToRefund"/>
        <v-text-field type="number" :value="amountToRefund" label="Total à rembourser" readonly />
      </template>
      <template v-slot:actions>
        <div v-if="errorMessage" class="error--text">{{ errorMessage }}</div>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Annuler</v-btn>
        <v-btn color="primary" elevation="0" @click="refundOrder" :loading="loading">Rembourser</v-btn>
      </template>
    </SimpleDialog>
    <SimpleDialog title="Remboursement non disponible" :dialog="showRefundAlert">
      <template v-slot:content>
        Le remboursement n'est pas disponible pour les transactions SOGECOM.
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn text @click="refundOrder">Ok</v-btn>
      </template>
    </SimpleDialog>
  </div>
</template>

<style scoped lang="scss">

</style>