import {UserApi} from "@/api/index.ts";

const state = () => ({
    users: [],
    loading: false,
    error: null
})

const actions = {

    getUserInfo({commit, state}, userId) {
        commit("setLoading", true)

        if (state.user) {
            return state.user
        }

        return UserApi.getUserInfo(userId)
            .then(response => {
                commit('setUser', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },


}

const mutations = {

    setUser(state, user) {
        let index = state.users.findIndex(o => o.userId === user.userId)
        if (index === -1) {
            state.users.push(user)
        } else {
            state.users[index] = user
        }
    },

    setError(state, error) {
        state.error = error
    },

    setLoading(state, loading) {
        if (loading) state.error = null
        state.loading = loading
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}