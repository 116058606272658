import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import {keycloakOptions, VueKeyCloak} from "./plugins/keycloak";

import '@/plugins/moment'

import './styles/main.scss'
import axios, { AxiosRequestConfig } from "axios";

import MobileUtils from "@/plugins/mobile-utils";
Vue.use(MobileUtils)


import DateUtils from "@/plugins/dateUtils";
Vue.use(DateUtils)

import ProductUtils from "@/plugins/productUtils";
Vue.use(ProductUtils)

Vue.config.productionTip = false


interface CommonHeaderProperties extends AxiosRequestConfig {
    Authorization: string;
}

Vue.use(VueKeyCloak, Object.assign(keycloakOptions, {
    onReady: () => {
        axios.interceptors.request.use(config => {
            if (Vue.prototype.$keycloak.authenticated) {
                config.headers = config.headers ?? {};
                config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
            }
            return config
        }, error => {
            return Promise.reject(error)
        })

        new Vue({
            el: '#app',
            router,
            store,
            vuetify,
            render: h => h(App)
        })
    }
}))




