import axios from 'axios'

export default {

    async isUp() {
       try {
           const response = await axios.get("/health")
           return response.status === 200
       } catch (e) {
           return false
       }

    },

}