import _ from "lodash";

export default {

    install(Vue) {

        Vue.prototype.$productName = function (product) {
            let name = product.productName;
            if (product.sellQuantity > 1 && name) {
                return `${name.replace(/\(x1\)/g, "")} (Jeu de ${product.sellQuantity})`

            } else if (name) return shouldPreciseIsUnitary()
                ? `${name.replace(/\(x1\)/g, "")} (x1)`
                : name
            else return null
        }

        Vue.prototype.$unitPriceHT = function(product) {
            return product.priceHT * product.sellQuantity
        }

        Vue.prototype.$unitPriceTTCWithDeposit = function(product) {
            let price = (this.$unitPriceTTCWithoutDeposit(product)) + (product.priceTTCDeposit ?? 0);
            return _.round(price, 2)
        }

        Vue.prototype.$unitPriceTTCWithoutDeposit = function(product) {
            let price = (this.$unitPriceHT(product) * 1.2)
            return _.round(price, 2)
        }

        Vue.prototype.$totalPriceTTCWithDeposit = function(product, quantity) {
            let price = (this.$unitPriceHT(product) * quantity * 1.2) + (product.priceTTCDeposit ?? 0) * quantity;
            return _.round(price, 2);
        }

        Vue.prototype.$publicPriceTTC = function (product) {
            let price = product.publicPriceHT * product.sellQuantity * 1.2
            return _.round(price, 2)
        }
    }
}

function shouldPreciseIsUnitary(subCategory, criterion) {
    // ammortisseurs
    return subCategory === 2 && criterion === 11 || // avant
        subCategory === 2 && criterion === 12 || // arrière
        // disques
        subCategory === 5 && criterion === 1 || // avant
        subCategory === 5 && criterion === 2 || // arrière

        // roulements
        subCategory === 5 && criterion === 5 || // avec roulements

        // tambours
        subCategory === 6 && criterion === 5
}

