<template>
  <Block v-if="shouldBeDisplayed" class="dashboard-item" link :to="url" no-header no-footer no-margin>
    <div>
      <div class="orders-number">{{items.length}}</div>
      <div>{{boxText}}</div>
      <StatusTag v-if="type === 'order'" :value="status" large></StatusTag>
      <ReturnStatusTag v-else-if="type === 'return'" :value="status" large></ReturnStatusTag>
    </div>
  </Block>
</template>

<script>
import Block from "./SimpleBlock.vue";
import StatusTag from "./StatusTag";
import ReturnStatusTag from "@/components/ReturnStatusTag.vue";

export default {
  components: {ReturnStatusTag, Block, StatusTag},
  computed: {
    items() {
      switch (this.type) {
        case "order":
          return this.$store.getters['orders/getOrdersByStatus'][this.status]
        case "return":
          return this.$store.getters['returns/getReturnsByStatus'][this.status]
        default:
          return []
      }
    },
    shouldBeDisplayed() {
      return this.items && this.items.length > 0
    },
    url() {
      switch (this.type) {
        case "order":
          return `/orders?status=${this.status}`
        case "return":
          return `/returns?status=${this.status}`
        default:
          return null
      }
    },
    boxText() {
      switch (this.type) {
        case "order":
          return "commandes"
        case "return":
          return "demandes de retour"
        default:
          return null
      }
    }
  },
  props: {
    status: String,
    type : String
  }

}
</script>

<style lang="scss" scoped>
.dashboard-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 300px;
  margin-bottom: 32px !important;

  .orders-number {
    font-size: xxx-large;
    text-align: center;
  }
}
</style>
