import {ShopApi} from "@/api/index";
import { ShopsState} from "@/store/modules/shop.types";
import {Shop} from "shared-model";

const state = () => ({
    shop: null,
    loading: false,
    error: null,
    shopId: "ec820c0b-3bf4-48d0-aac2-98db0cbbde11"
})


const actions = {

    loadShop({commit, state}: {commit: any, state: ShopsState}) {
        commit("setLoading", true)

        return ShopApi.getById(state.shopId)
            .then(response => {
                commit('setShop', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    async update({commit}: {commit: any}, shop: Shop) {

        const response = await ShopApi.update(shop)
        commit("setShop", response)
        return response
    }
}

const mutations = {

    setShop(state: ShopsState, shop: Shop) {
        state.shop = shop
    },

    setError(state: ShopsState, error: string) {
        state.error = error
    },

    setLoading(state: ShopsState, loading: boolean) {
        if (loading) state.error = undefined
        state.loading = loading
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
