import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "../views/DashboardPage.vue";
import Orders from "../views/orders/OrdersPage.vue";
import Vouchers from "../views/VouchersPage.vue";
import Order from "../views/orders/OrderPage.vue";
import Voucher from "../views/VoucherPage.vue";
import Stats from "../views/StatsPage.vue";
import NotAuthorized from "../views/NotAuthorized";
import Returns from "../views/ReturnsPage.vue";
import Return from "../views/ReturnPage.vue";
import Settings from "../views/settings/SettingsPage.vue";
import Expeditions from "../views/ExpeditionsPage.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Dashboard
  },
  {
    path: '/denied',
    component: NotAuthorized
  },
  {
    path: '/orders',
    component: Orders,
    children: [{
      path: ':id/:userId',
      components: {
        customer: Order
      }
    }]
  },
  {
    path: '/expeditions',
    component: Expeditions,
    // children: [{
    //   path: ':id/',
    //   components: {
    //     customer: Order
    //   }
    // }]
  },
  {
    path: '/stats',
    component: Stats
  },
  {
    path: '/settings',
    component: Settings
  },
  {
    path: '/vouchers',
    component: Vouchers,
    children: [{
      path: 'create',
      components: {
        default: Voucher
      }
    }, {
      path: ':id',
      components: {
        default: Voucher
      }
    }]
  },
  {
    path: "/returns",
    component: Returns,
    children: [{
      path: ':id',
      components: {
        default: Return
      }
    }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
