<template>
  <Block title="Jours fermés" class="settings-block" no-padded>
    <v-list>
      <template v-for="(closedDay, i) in modifiedClosedDays">
        <v-divider :key="`divider-${i}`" ></v-divider>
        <v-list-item :key="`closed-days-${i}`" three-line>
          <div class="list-item">
            <div class="list-item-edit" v-if="isEdited(i)">
              <v-text-field label="Libellé" :value="closedDay.reason"
                            @input="updateClosedDay('reason', i, $event)"></v-text-field>
              <DateField name="start" label="Début" :value="closedDay.dates ? closedDay.dates[0] : null"
                         @on-update="updateDateClosedDay($event, i)"></DateField>
              <DateField name="end" label="Fin" @on-update="updateDateClosedDay($event, i)" :readonly="cannotEditEndDate(i)"
                         :value="closedDay.dates && closedDay.dates.length > 1 ? closedDay.dates[closedDay.dates.length - 1] : null"></DateField>
            </div>
            <div v-else>
              <v-list-item-title>{{closedDay.reason}}</v-list-item-title>
              <v-list-item-subtitle>
                {{ computedClosingDay(closedDay.dates) }}
              </v-list-item-subtitle>
            </div>
          </div>

          <v-list-item-action>
            <v-flex v-if="isEdited(i)">
              <v-btn text color="primary" @click="saveItem(i)">OK</v-btn>
              <v-btn elevation="0" color="red" @click="deleteItem(i)" outlined><v-icon color="red" >mdi-delete</v-icon></v-btn>
            </v-flex>
            <v-btn v-else text color="primary" @click="editItem(i)">Editer</v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list-item>
        <div style="display: flex; flex: 1; justify-content: center"><v-btn text @click="addClosedDays">Ajouter</v-btn></div>
      </v-list-item>
    </v-list>

    <template v-slot:footer-actions>
        <v-btn v-if="unsavedChange" text @click="cancel">Annuler</v-btn>
        <v-btn text color="primary" @click="save" :loading="loading" :disabled="!unsavedChange">Sauvegarder</v-btn>
    </template>
  </Block>
</template>

<script>
import Block from "../../components/SimpleBlock.vue";
import DateField from "../../components/DateField";
import _ from 'lodash'

export default {
  name: "ClosedDaysEditor",
  components: { Block , DateField },
  props: {
    value: Array,
    loading: Boolean
  },
  data() {
    return {
      editedIndex: [],
      closedDays: this.value ?? [],
      modifiedClosedDays: JSON.parse(JSON.stringify(this.value ?? [])),
    }
  },
  watch: {
    value(closeDays) {
      this.closedDays = closeDays ?? []
      this.modifiedClosedDays = JSON.parse(JSON.stringify(closeDays ?? []))
    }
  },
  computed: {
    unsavedChange() {
      return !_.isEqual(this.closedDays, this.modifiedClosedDays)
    }
  },
  methods: {
    updateClosedDay(key, index, value) {
      this.modifiedClosedDays[index][key] = value
    },
    updateDateClosedDay({key, value}, index) {

      let closedDay = this.modifiedClosedDays[index];
      let length = closedDay?.dates?.length;

      if (key === 'start') {
        if (!length || length === 0) {
          closedDay.dates = [value]
        }
        else if (length >= 1) {
          closedDay.dates.splice(0, 1, value)
        }


      } else if (key === 'end') {
        if (length || length === 0)
          closedDay.dates.push(value)
        if (length === 1)
          closedDay.dates.push(value)
        else if (length === 2)
          closedDay.dates[1] = value
      }
      this.$set(this.modifiedClosedDays, index, closedDay)
    },
    cannotEditEndDate(index) {
      return !this.modifiedClosedDays[index].dates ||
          this.modifiedClosedDays[index].dates?.length === 0;
    },
    editItem(i) {
      this.editedIndex.push(i)
    },
    saveItem(i) {
      let index = this.editedIndex.findIndex(a => a === i)
      this.editedIndex.splice(index, 1)
    },
    deleteItem(i) {
      let index = this.editedIndex.findIndex(a => a === i)
      this.editedIndex.splice(index, 1)
      this.modifiedClosedDays.splice(i, 1)
    },
    isEdited(index) {
      return this.editedIndex.findIndex(i => i === index) !== -1
    },
    addClosedDays() {
      this.modifiedClosedDays.push({})
      this.editedIndex.push(this.modifiedClosedDays.length - 1)
    },
    computedClosingDay(days) {

      if (!days) return

      if (days.length === 1)
        return `le ${this.$formatDate(days[0], 'DD/MM/YYYY')}`

      if (days.length > 1)
        return `du ${this.$formatDate(days[0], 'DD/MM/YYYY')} au ${this.$formatDate(days[days.length - 1], 'DD/MM/YYYY')}`
    },
    cancel() {
      this.modifiedClosedDays = JSON.parse(JSON.stringify(this.closedDays))
    },
    save() {
      this.editedIndex = []
      this.closedDays = JSON.parse(JSON.stringify(this.modifiedClosedDays))
      this.$emit("on-save", this.modifiedClosedDays)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  flex: 1;

  .list-item-edit {
    .v-input {
      margin-right: 1em;
    }

    .field-container {
      margin-right: 1em;
    }
  }

  .list-item-edit {
    display: flex;
  }
}
</style>
