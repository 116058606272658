'use strict'

import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import _ from 'lodash'

import orders from './modules/orders'
import users from './modules/users'
import vouchers from './modules/vouchers'
import returns from './modules/returns'
import shops from './modules/shops'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: true });
const encryptedLocal = {
    getItem: (key: string) => {
        const text = ls.get(key);
        return !_.isEmpty(text) ? JSON.parse(text) : text
    },
    setItem: (key: string, value: any) => ls.set(key, JSON.stringify(value)),
    removeItem: (key: string) => ls.remove(key),
}

const dataState = createPersistedState({
    key: "dpa-store",
    paths: [],
    storage: debug ? localStorage : encryptedLocal,
    reducer: (state: any) => {
        if (!Vue.prototype.$keycloak.authenticated) return {} // FIXME
        else return state
    },
})

const plugins = [dataState]
if (debug) plugins.push(createLogger())

export default new Vuex.Store({
    modules: {
        orders,
        users,
        vouchers,
        returns,
        shops
    },
    strict: debug,
    plugins: plugins
})

