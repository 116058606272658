<template>
  <div class="voucher-edit-panel">
    <div class="header">
      <div style="display: flex; align-items: center">
        <v-icon class="menu-icon" @click="close()" size="48">mdi-chevron-left</v-icon>
        <h2>{{ pageName }}</h2>
      </div>
      <div class="actions-container">
        <v-menu bottom left offset-y close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="menu-icon">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in voucherMenuItems" :key="`submenu-${i}`" @click="item.action()">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <Block>

      <v-form ref="voucherForm" lazy-validation>

        <v-text-field :value="modifiedVoucher.code ? modifiedVoucher.code.toUpperCase() : modifiedVoucher.code" label="Voucher code" @input="update('code', $event ? $event.toUpperCase() : null)"
                      :rules="[required]" autocomplete="off" :readonly="!isCreation"></v-text-field>

        <v-text-field :value="modifiedVoucher.description" @input="update('description', $event)"
                      label="Description de la Remise" autocomplete="off"></v-text-field>



        <div class="field-row">
          <v-select :items="$store.state.vouchers.discountTypes" item-text="value" item-value="key" label="Type de remise"
                    :rules="[required]" :value="modifiedVoucher.discountType" @input="update('discountType', $event)"></v-select>

          <v-text-field autocomplete="off" :value="modifiedVoucher.discount" @input="update('discount', $event)"
                        :rules="modifiedVoucher.discountType === 'PERCENT' ? [required, max100] : [required]" type="number" label="Valeur de la remise"></v-text-field>
        </div>

        <DateField name="expirationDate" label="Date d'expiration"
                   placeholder="sélectionnez une date"
                   :value="modifiedVoucher.expirationDate" @on-update="updateExpirationDate"/>

        <div class="field-row">
          <v-checkbox class="small" v-model="addThreshold" label="Ajouter un palier"></v-checkbox>
          <v-select :items="$store.state.vouchers.thresholds" item-text="value" item-value="key" label="Type de déclencheur"
                    :rules="addThreshold ? required : []" :value="modifiedVoucher.thresholdType" @input="update('thresholdType', $event)" :disabled="!addThreshold"></v-select>

          <v-text-field autocomplete="off"
                        :value="modifiedVoucher.thresholdValue === 0 ? undefined : modifiedVoucher.thresholdValue"
                        @input="update('thresholdValue', $event)"
                        :rules="addThreshold ? required : []" type="number" label="Valeur du déclencheur (en €)" :disabled="!addThreshold"></v-text-field>
        </div>

        <v-checkbox class="small" :value="modifiedVoucher.uniquePerCustomer" @change="update('uniquePerCustomer', $event)" label="Usage unique par client"></v-checkbox>
        <div class="field-row">
        <v-checkbox class="small" v-model="personalVoucher" label="Voucher personnel"></v-checkbox>
        <v-text-field v-if="personalVoucher" :value="modifiedVoucher.userId" @input="update('userId', $event)"
                      label="Id de l'utilisateur" autocomplete="off" readonly></v-text-field>
        </div>
      </v-form>

      <template v-slot:footer-actions>
          <span v-if="voucherFormError" class="error--text">{{voucherFormError}}</span>
          <v-spacer></v-spacer>
          <v-btn v-if="unsavedChange" @click="cancel" text>Annuler</v-btn>
          <v-btn text color="primary" :disabled="!unsavedChange" @click="saveVoucher" :loading="loading">Sauvegarder</v-btn>
      </template>

    </Block>

  </div>
</template>

<script>
import Block from "../components/SimpleBlock.vue";
import Vue from "vue";
import DateField from "../components/DateField";
import _ from "lodash";
import moment from 'moment'

export default {

  components: {Block, DateField},
  data() {
    return {
      voucher: {},
      modifiedVoucher: {},
      voucherFormError: null,
      loading: false,
      personalVoucher: false,
      addThreshold: false,
      voucherMenuItems: [{
        title: "Supprimer",
        action: () => this.deleteVoucher()
      }],
      required: value => !!value || "Champ obligatoire",
      max100: value => value <= 100 || "max 100 %"
    }
  },
  watch: {
    addThreshold(val) {
      if (!val) {
        Vue.set(this.modifiedVoucher, 'thresholdType', null)
        Vue.set(this.modifiedVoucher, 'thresholdValue', null)
      }
    }
  },
  computed: {
    pageName() {
      return this.voucher && this.voucher.id ? `Voucher ${this.voucher.code}` : "Nouveau voucher"
    },
    isCreation() {
      return this.$route.path.indexOf("create") !== -1
    },
    unsavedChange() {
      return !_.isEqual(this.voucher, this.modifiedVoucher)
    },
  },
  methods: {

    update(key, val) {

      if (key === 'uniquePerCustomer' && !val) val = false

      this.voucherFormError = null
      Vue.set(this.modifiedVoucher, key, val)
    },
    close() {
      this.$router.replace("/vouchers")
    },
    updateExpirationDate(date) {
      Vue.set(this.modifiedVoucher, date.key, moment(date.value).toISOString())
    },
    saveVoucher() {

      let isValid = this.$refs.voucherForm.validate();
      if ((isValid && this.modifiedVoucher.discountType !== 'PERCENT') || (isValid && this.modifiedVoucher.discount <= 100 )) {

        let action = this.voucher.id
            ? "vouchers/update"
            : "vouchers/add"

        this.loading = true
        this.$store.dispatch(action, this.modifiedVoucher).then((response) => {
          if (this.isCreation) this.$router.replace(`/vouchers/${response.id}`)
          else {
          if (response) {
            this.voucher = Object.assign({}, response)
            this.modifiedVoucher = Object.assign({}, response)
          }
          }
        }).catch(e => {
          this.voucherFormError = e
        }).finally(() => {
          this.loading = false
        })
      }
    },
    cancel() {
      this.voucherFormError = null
      let originalVoucher = Object.assign({}, this.voucher)
      Vue.set(this, 'voucher', originalVoucher)
     this.modifiedVoucher = Object.assign({}, originalVoucher)

      if (this.voucher.userId) this.personalVoucher = true
      if (this.voucher.thresholdType) this.addThreshold = true
    },
    deleteVoucher() {
      this.$store.dispatch("vouchers/delete", this.voucher.id).then(() => {
        this.$router.replace("/vouchers")
      })
    },
    async fetchItem() {
      let id = this.$route.params.id
      if ((_.isEmpty(this.voucher) || this.voucher.id !== id) && !this.isCreation) {

        let existingVoucher = this.$store.getters["vouchers/getVoucherById"](id)
        if (existingVoucher) this.voucher = existingVoucher

        let voucher = await this.$store.dispatch("vouchers/getById", id);
        if (voucher) {
          this.voucher = Object.assign({}, voucher)
          this.modifiedVoucher = Object.assign({}, voucher)
        }

        if (this.voucher.userId) this.personalVoucher = true
        if (this.voucher.thresholdType) this.addThreshold = true
      }
    },
  },
  created() {
    this.fetchItem()
  },
  updated() {
    this.fetchItem()
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/ContentPanel";

.small {
  max-width: 200px;
}

</style>