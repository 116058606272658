<template>
  <div class="page page-content">

      <div v-if="error" class="empty-dashboard">
        <v-img src="@/assets/thumb_down.png" class="thumb-up" height="250" width="250"></v-img>
        <h1>Oops une erreur est survenue.</h1>
        <p>{{ error }}</p>
      </div>
      <div v-else-if="nothingToDo" class="empty-dashboard">
        <v-img src="@/assets/thumbs_up.png" class="thumb-up" height="250" width="250"></v-img>
        <h1>Rien à faire par ici :)</h1>
      </div>
      <div v-else class="orders-container">
        <div class="order-lane" v-if="!$isMobile() || errorOrders.length > 0">
          <h3>En erreur</h3>
          <div>
            <DashboardBox :status="status" v-for="status in errorStatuses" v-bind:key="`errors-${status}`" type="order"/>
          </div>
        </div>

        <div class="order-lane" v-if="!$isMobile() || todoOrders.length > 0">
          <h3>A Faire</h3>
          <div>
            <DashboardBox :status="status" v-for="status in todoStatuses" v-bind:key="`todo-${status}`" type="order"/>
            <DashboardBox :status="status" v-for="status in returnsTodoStatuses" v-bind:key="`returns-todo-${status}`" type="return"/>
          </div>
        </div>

        <div class="order-lane" v-if="!$isMobile() || waitingOrders.length > 0">
          <h3>En attente</h3>
          <div>
            <DashboardBox :status="status" v-for="status in waitingStatuses" v-bind:key="`waiting-${status}`" type="order"/>
            <DashboardBox :status="status" v-for="status in returnsWaitingStatuses" v-bind:key="`returns-waiting-${status}`" type="return"/>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import DashboardBox from "../components/DashboardBox.vue";
import {mapState} from "vuex";

export default {
  components: {DashboardBox},
  data() {
    return {
      errorStatuses: ["CREATED", "PAID", "ERROR"],
      todoStatuses: ["PLACED", "TO_PREPARE", "TO_DISPATCH"],
      waitingStatuses: ["ORDERED", "AVAILABLE", "GIVEN_TO_CARRIER"],

      returnsTodoStatuses: ["CREATED"],
      returnsWaitingStatuses: ["IN_PROGRESS"]
    }
  },
  computed: {
    ...mapState({
      error: state => state.orders.error,
      nothingToDo(state) {
        return state.orders.orders.filter(o => this.openStatuses.indexOf(o.status) !== -1).length === 0
      },
      todoOrders(state) {
        return state.orders.orders.filter(o => this.todoStatuses.indexOf(o.status) !== -1)
      },
      todoReturns(state) {
        return state.returns.returns.filter(o => this.returnsTodoStatuses.indexOf(o.status) !== -1)
      },
      waitingReturns(state) {
        return state.returns.returns.filter(o => this.returnsTodoStatuses.indexOf(o.status) !== -1)
      },
      waitingOrders(state) {
        return state.orders.orders.filter(o => this.waitingStatuses.indexOf(o.status) !== -1)
      },

      errorOrders(state) {
        return state.orders.orders.filter(o => this.errorStatuses.indexOf(o.status) !== -1)
      },

    }),
    openStatuses() {
      return this.errorStatuses.concat(this.todoStatuses, this.waitingStatuses)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";

.empty-dashboard {
display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.orders-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .order-lane {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      text-align: center;
      padding: 1em;
    }
  }

  .thumb-up {
    color: $green;
  }


}

@media only screen and (max-width: 1000px) {

  .orders-container {
    flex-direction: column-reverse;
  }
}



</style>
