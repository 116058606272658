import axios from 'axios'
import { Voucher, VoucherStatus } from 'shared-model'
export default {

    async getAll(status: VoucherStatus) {

        const params = new URLSearchParams();
        if (status) {
            params.append("status", status);
        }

        try {

            const response = await axios.get(`/vouchers`, { params })
            return response.data

        } catch (e) {
            console.log("e : " + JSON.stringify(e))
            throw e
        }
    },

    async getById(voucherId: string) {

        try {

            const response = await axios.get(`/vouchers/${voucherId}`)
            return response.data

        } catch (e) {
            console.log("e : " + JSON.stringify(e))
            throw e
        }
    },

    async add(voucher: Voucher) {

        try {

            const response = await axios.post(`/vouchers`, voucher)
            return response.data

        } catch (e: any) {
            if (e.message.indexOf("409")) throw 409
            throw e
        }
    },

    async update(voucher: Voucher) {

        try {

            const response = await axios.put(`/vouchers`, voucher)
            return response.data

        } catch (e) {
            console.log("e : " + JSON.stringify(e))
            throw e
        }
    },

    async delete(voucherId: string) {
        try {

            const response = await axios.delete(`/vouchers/${voucherId}`,)
            return response.data

        } catch (e) {
            console.log("e : " + JSON.stringify(e))
            throw e
        }
    },
    async validateVoucher(voucherCode: string, userId: string) {
        try {
            const response = await axios.post(`/vouchers/validate/${userId}`, voucherCode)
            return response ? response.data : null
        } catch (e: any) {
            console.log("e : " + JSON.stringify(e))
            if (e.message.indexOf("404")) throw 404
            throw 500
        }
    }
}