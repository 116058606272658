<script>
import Vue from "vue";
import SimpleDialog from "@/components/SimpleDialog.vue";

export default Vue.extend({
  name: "OrderErrorDialog",
  components: {SimpleDialog},
  props: {
    order: Object,
    showDialog: Boolean
  },
  data() {
    return {
      loading: false,
      orderErrorMessage: null,
      editMessageError: null
    }
  },
  methods: {
    async onValidate() {
      try {
        this.loading = true
        const response = await this.$store.dispatch("orders/changeStatus", {
          action: 'ORDER_IN_ERROR',
          payload: [{
            orderId: this.order.orderId,
            userId: this.order.userId,
            message: this.orderErrorMessage,
          }]
        })
        if (response.error) {
          this.editMessageError = response.errorCode
        } else {
          this.$emit("on-success", response.order)
          this.orderErrorMessage = null
        }
      } catch (e) {
        this.editMessageError = e
      } finally {
        this.loading = false
      }
    },
    onCancel() {
      this.orderErrorMessage = null
      this.$emit("on-cancel")
    },
  }
})

</script>

<template>
  <SimpleDialog title="Ajouter un message d'erreur" :dialog="showDialog" :full-width="$isMobile()">
    <template v-slot:content>
      <v-textarea placeholder="Ce message est privé et n'est pas communiqué au client"
                  v-model="orderErrorMessage"></v-textarea>
    </template>
    <template v-slot:actions>
      <div v-if="editMessageError" class="error--text">{{ editMessageError }}</div>
      <v-spacer></v-spacer>
      <v-btn color="white darken-1" elevation="0" @click="onCancel">
        Annuler
      </v-btn>
      <v-btn color="primary" elevation="0" :loading="loading" @click="onValidate">Valider</v-btn>
    </template>
  </SimpleDialog>
</template>

<style scoped lang="scss">

</style>