import { ReturnsApi } from "@/api/index.ts";
import {groupBy} from "lodash";

const state = () => ({
    returns: [],
    returnStatuses: {
        CREATED: "En cours",
        IN_PROGRESS: "En cours",
        TERMINATED: "Terminée"
    },
    returnItemStatuses: {
        CREATED: "En cours",
        VALIDATED: "Validée",
        CHECKING: "En cours",
        DECLINED: "Rejetée"
    },
    reasons: {
        NOT_SUITABLE: "Pas adapatée au véhicule",
        BROKEN: "Pièce endommagée ou incomplête",
        RETURN_DEPOSIT: "Retour consigne",
        WARRANTY: "Demande de garantie"
    },
    loading: false,
    error: null
})

const getters = {
    getReturnStatusName: (state) => status => {
        return state.returnStatuses[status]
    },
    getReturnItemStatusName: (state) => status => {
        return state.returnItemStatuses[status]
    },
    getReturnsByStatus: (state) => {
        return groupBy(state.returns, 'status')
    },
    getReason: state => status => {
        return state.reasons[status]
    },
    getById: state => id => {
        return state.returns.find(r => r.id === id)
    }
}

const actions = {

    async fetchReturns({commit}, payload) {
        commit("setLoading", true)

        try {
            let response = await ReturnsApi.findAllReturns(payload)
            commit('setReturns', response)
            return response
        } catch (e) {
            commit('setError', e)
            throw e
        } finally {
            commit("setLoading", false)
        }
    },

    async getById({commit}, id) {
        commit("setLoading", true)

        try {
            let response = await ReturnsApi.getById(id)
            commit('setReturn', response)
            return response
        } catch (e) {
            commit('setError', e)
            throw e
        } finally {
            commit("setLoading", false)
        }
    },

    async delete({commit}, id) {
        commit("setLoading", true)

        try {
            await ReturnsApi.delete(id)
            commit('removeReturn', id)
        } catch (e) {
            commit('setError', e)
            throw e
        } finally {
            commit("setLoading", false)
        }
    },

    async updateItem({commit}, payload) {
        commit("setLoading", true)

        try {
            let returnItem = await ReturnsApi.updateItem(payload)
            commit('setReturn', returnItem)
            return returnItem
        } catch (e) {
            commit('setError', e)
            throw e
        } finally {
            commit("setLoading", false)
        }
    },
}

const mutations = {

    setReturns(state, returns) {
        state.returns = returns
    },

    setReturn(state, returnItem) {
      let index = state.returns.findIndex(r => r.id === r.id)
      if (index !== -1) {
          state.returns[index] = returnItem
      }  else {
          state.returns.push(returnItem)
      }
    },

    removeReturn(state, returnId) {

        let returns = state.returns
        let index = returns.findIndex(r => r.id === returnId)
        if (index !== -1) {
            returns.splice(index, 1)
        }

        state.returns = returns
    },

    setError(state, error) {
        state.error = error
    },

    setLoading(state, loading) {
        if (loading) state.error = null
        state.loading = loading
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
