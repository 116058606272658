<template>
  <div class="header">
    <div style="display: flex; align-items: center">
      <v-icon class="menu-icon" @click="closePanel" size="48">mdi-chevron-left</v-icon>
      <h2>{{ `Commande ${orderId}` }}</h2>
    </div>
    <div class="actions-container">
      <v-progress-circular v-if="loading" size="24" width="3" indeterminate
                           style="margin-right: 1em"></v-progress-circular>
      <div v-if="error" class="error--text" style="margin-right: 1em; margin-top: 0.3em">{{error}}</div>
      <StatusTag :value="orderStatus" large></StatusTag>
      <v-menu v-if="estimateMenuItems.length > 0" bottom left offset-y close-on-content-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="menu-icon">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in estimateMenuItems" :key="`submenu-${i}`" @click="item.action()">
            <v-list-item-title>
              <div style="display: flex; align-items: center;">
                {{ item.title }}
                <div class="warning-icons" v-if="item.icons">{{item.icons}}</div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import StatusTag from "@/components/StatusTag.vue";

export default {
  name: "OrderHeader",
  components: {StatusTag},
  props: {
    item: Object,
    loading: Boolean,
    error: String
  },
  data() {
    return {
      order: this.item
    }
  },
  methods: {
    closePanel() {
      this.$router.replace("/orders")
    },
    executeOrderAction(action) {
      this.$emit('on-order-action', action)
    },
  },
  computed: {
    orderId() {
      return this.order?.orderId ? `n° ${this.order.orderId.substring(0, 8).toUpperCase()}` : null
    },
    orderStatus() {
      return this.order?.status
    },
    estimateMenuItems() {

      let menu = []
      switch (this.order.status) {
        case "CREATED":
          menu.push({
            title: "Ajouter les infos de paiement",
            action: () => this.executeOrderAction('VALIDATE_PAYMENT')
          }, {
            title: "Supprimer",
            action: () => this.executeOrderAction('DELETE_ORDER')
          })
          break;

        case "PAID":
          menu.push({
                title: "Ajouter la commande à WinPro",
                action: () => this.executeOrderAction('CREATE_WINPRO_ORDER')
              },
              {
                title: "Indiquer un problème sur la commande",
                action: () => this.executeOrderAction('ORDER_IN_ERROR')
              })
          break;

        case "PLACED":
          menu.push({
            title: "Valider la commande",
            action: () => this.executeOrderAction('ORDERED_PRODUCTS')
          }, {
            title: "Indiquer un problème sur la commande",
            action: () => this.executeOrderAction('ORDER_IN_ERROR')
          })
          break;

        case "ORDERED":

          menu.push({
            title: "Valider la préparation",
            icons: '\u2709',
            action: () => this.executeOrderAction('ORDER_PREPARED')
          })

          menu.push({
            title: "Indiquer un problème sur la commande",
            action: () => this.executeOrderAction('ORDER_IN_ERROR')
          })
          break;

        case "TO_DISPATCH":
          if (this.order.deliveryType === 'CHRONOPOST_RELAY')
            menu.push({
              title: "Imprimer l'étiquette de transport",
              action: () => this.executeOrderAction('PRINT_TRANSPORT_LABEL')
            },{
              title: "Remis au transporteur",
              action: () => this.executeOrderAction('ORDER_GIVEN_TO_CARRIER')
            })

          menu.push({
            title: "Indiquer un problème sur la commande",
            action: () => this.executeOrderAction('ORDER_IN_ERROR')
          })
          break;

        case "TO_PREPARE":
          menu.push({
            title: "Valider la préparation",
            icons: '\u2709',
            action: () => this.executeOrderAction('ORDER_PREPARED')
          }, {
            title: "Indiquer un problème sur la commande",
            action: () => this.executeOrderAction('ORDER_IN_ERROR')
          })
          break;

        case "AVAILABLE":
          if (this.order.deliveryType === 'DRIVE') {
            menu.push({
              title: "Finaliser la commande",
              action: () => this.executeOrderAction('ORDER_GIVEN_TO_CUSTOMER')
            })
          }
          menu.push({
            title: "Indiquer un problème sur la commande",
            action: () => this.executeOrderAction('ORDER_IN_ERROR')
          })
          break;

        case "GIVEN_TO_CARRIER":
          menu.push({
            title: "Finaliser la commande",
            action: () => this.executeOrderAction('ORDER_GIVEN_TO_CUSTOMER')
          })
          break;

        case "ERROR":

          if (!this.order.externalId) {
            menu.push({
              title: "Ajouter la commande a WinPro",
              action: () => this.executeOrderAction('CREATE_WINPRO_ORDER')
            })
          }

          menu.push({
                title: "Valider la commande",
                action: () => this.executeOrderAction('ORDERED_PRODUCTS')
              },
              {
                title: 'Valider la préparation',
                icons: '\u2709',
                action: () => this.executeOrderAction('ORDER_PREPARED')
              })

          if (this.order.deliveryType !== 'DRIVE' && this.order.delivery.trackingNumber) {
            menu.push({
              title: 'Annuler l\'étiquette de transport',
              action: () => this.executeOrderAction('CANCEL_TRANSPORT_LABEL')
            })
          }

          menu.push({
                title: "Générer un avoir pour ce client",
                action: () => this.executeOrderAction('GENERATE_VOUCHER')
              },
              {
                title: "Rembourser",
                action: () => this.executeOrderAction('REFUND_ORDER')
              },
              {
                title: "Supprimer",
                action: () => this.executeOrderAction('DELETE_ORDER')
              })
          break;
      }

      return menu
    }
  },
  watch: {
    item(val) {
      this.order = val
    }

  }
}
</script>

<style scoped lang="scss">

</style>