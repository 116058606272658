<template>
  <span :class="tagClass">{{ $store.getters['returns/getReason'](status) }}</span>
</template>

<script>
export default {
  props: {
    value: String,
    large: Boolean,
    xLarge: Boolean
  },
  data() {
    return {
      status: this.value,
      tagsClass: {
        CREATED: {
          tagClass: "tag orange"
        },
        CLOSED: {
          tagClass: "tag green"
        },
        DECLINED: {
          tagClass: "tag red"
        }
      }
    }
  },
  computed: {
    tagClass() {
      let clazz= ""
      let tag = this.tagsClass[this.status];
      if (!tag) clazz = "tag red"
      else clazz = tag.tagClass

      if (this.large) clazz += " large"
      if (this.xLarge) clazz += " x-large"
      return clazz
    }
  },
  watch: {
    value(val) {
      this.status = val
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

.tag {
  font-weight: bold;
  font-size: medium;
  background-color: transparent !important;
}

.tag.large {
  font-size: large;
}

.tag.x-large {
  font-size: x-large;
}

.tag.red {
  color: $red;
  background-color: inherit !important;
}

.tag.green {
  color: $green;
  background-color: inherit !important;
}

.tag.yellow {
  color: $yellow;
  background-color: inherit !important;
}
.tag.orange {
  color: $orange;
  background-color: inherit !important;
}
</style>
