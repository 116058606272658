<script lang="ts">
import {defineComponent} from 'vue'
import SimpleBlock from "@/components/SimpleBlock.vue";
import _ from "lodash";
import {DayOfWeek, Hours} from "shared-model";
import TimePicker from "@/components/TimePicker.vue";
import {VForm} from "@/shims-tsx";
import moment from "moment";

export default defineComponent({
  name: "OpeningHoursEditor",
  components: {TimePicker, SimpleBlock},
  props: {
    value: Array,
    loading: Boolean,
    error: String
  },
  data() {
    return {
      editMode: false,
      openingHours: this.value ?? [],
      modifiedOpeningHours: JSON.parse(JSON.stringify(this.value ?? [])),
      errorMessage: this.error,
      required: (value: String) => !!value || "" || "Champ obligatoire",
      greaterThan: (valueToCompare: string) => (value: string) => {
        return moment(value, 'hh:mm').isAfter(moment(valueToCompare, 'hh:mm')) || "Doit être supérieur à l'heure de début"
      }
    }
  },
  watch: {
    errorMessage(error) {
      this.errorMessage = error
    },
    modifiedOpeningHours() {
      this.errorMessage = undefined
    },
  },
  computed: {
    unsavedChange(): Boolean {
      return !_.isEqual(this.openingHours, this.modifiedOpeningHours)
    },
    form(): VForm {
      return this.$refs.openingHoursForm as VForm
    }
  },
  methods: {
    cancel(){
      this.editMode = false
      this.errorMessage = undefined
      this.modifiedOpeningHours = JSON.parse(JSON.stringify(this.openingHours))
    },
    save(){

      this.errorMessage = undefined

      if (this.form.validate()) {
        this.openingHours = JSON.parse(JSON.stringify(this.modifiedOpeningHours))
        this.$emit("on-save", this.modifiedOpeningHours)
        this.editMode = false
      }
    },
    computeDay(day: string): String {
      const id = DayOfWeek.valueOf(day);
      return DayOfWeek.from(id) ?? ""
    },
    addHour(day: string){
      const modifiedOpeningHour = this.modifiedOpeningHours[DayOfWeek.valueOf(day) - 1];
      modifiedOpeningHour.hours.push({start: "", end: ""})
    },
    removeHour(day: string){
      const modifiedOpeningHour = this.modifiedOpeningHours[DayOfWeek.valueOf(day) - 1];
      modifiedOpeningHour.hours.pop()
    },
    updateDateClosedDay({key, value}: any, index: any, j: any) {
      let closedDay = this.modifiedOpeningHours[index];
      closedDay.hours[j][key] = value
    },
    computeMinTime(timeBefore?: Hours): string | undefined {
      if (timeBefore) {
        return timeBefore.end
      }
      return undefined
    }
  }
})
</script>

<template>
  <SimpleBlock class="settings-block" title="Horaires d'ouverture">

    <template v-slot:header-action>
      <v-btn v-if="!editMode" text color="primary" @click="editMode = true">Modifier</v-btn>
    </template>

    <v-form ref="openingHoursForm" class="opening-hours-week" lazy-validation>
      <div v-for="(openingHours, i) in modifiedOpeningHours" :key="`opening-hours-${i}`" class="opening-hours-day">
        {{computeDay(openingHours.day)}}
        <div v-if="openingHours.hours.length === 0">Fermé</div>
        <div v-for="(hour, j) in openingHours.hours" :key="`hours-${i}-${j}`">
          <div style="display: flex; gap: 1em">
            <TimePicker
                label="Début"
                name="start"
                :value="hour.start"
                :rules="[required]"
                :readonly="!editMode"
                :min="computeMinTime(openingHours.hours[j - 1])"
                @on-update="updateDateClosedDay($event, i, j)"
            />
            <TimePicker
                label="Fin"
                name="end"
                :value="hour.end"
                :min="hour.start"
                :readonly="!editMode"
                :rules="[required, greaterThan(hour.start)]"
                @on-update="updateDateClosedDay($event, i, j)"
            />
          </div>
        </div>
        <div class="opening-hours-day-buttons">
          <v-btn
              v-if="editMode"
              @click="addHour(openingHours.day)"
              rounded elevation="0">
            +
          </v-btn>
          <v-btn
              v-if="editMode && openingHours.hours.length > 0"
              @click="removeHour(openingHours.day)"
              rounded elevation="0">
            -
          </v-btn>
        </div>
      </div>
    </v-form>

    <template v-slot:footer-actions v-if="editMode">
      <span v-if="errorMessage" class="error--text">{{errorMessage}}</span>
      <v-spacer></v-spacer>
      <v-btn v-if="editMode" text @click="cancel">Annuler</v-btn>
      <v-btn text color="primary" @click="save" :loading="loading" :disabled="!unsavedChange">Sauvegarder</v-btn>
    </template>
  </SimpleBlock>
</template>

<style scoped lang="scss">

.opening-hours-week {
  display: flex;
  justify-content: space-between;
  gap: 2em;

  .opening-hours-day {
    display: grid;
    gap: 1em;
    .opening-hours-day-buttons {
      display: flex;
      justify-content: space-between;
      gap: 1em;
    }
  }
}
</style>