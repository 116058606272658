import { render, staticRenderFns } from "./OrderHeader.vue?vue&type=template&id=5810212e&scoped=true"
import script from "./OrderHeader.vue?vue&type=script&lang=js"
export * from "./OrderHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_qty7iymlffgpukvncpluvycwfu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5810212e",
  null
  
)

export default component.exports