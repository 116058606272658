import {VouchersApi} from "@/api/index.ts";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash'

const state = () => ({
    vouchers: [],
    vouchersStatus: {
        active: 'ACTIVE',
        expired: 'EXPIRED'
    },
    discountTypes: [{
        key: "AMOUNT",
        value: "Montant"
    }, {
        key: "PERCENT",
        value: "Pourcentage"
    }, {
        key: "WALLET",
        value: "Compte virtuel"
    }],
    thresholds: [{
        key: "CART_AMOUNT",
        value: "Montant du panier"
    }],
    loading: false,
    error: null
})

const getters = {
    getVoucherById: state => id => {
        let index = state.vouchers.findIndex(o => o.id === id);
        return index !== -1 ? state.vouchers[index] : null
    },
}

const actions = {

    getAll({commit, state}, status) {
        commit("setLoading", true)

        if (!status) status = state.vouchersStatus.active

        return VouchersApi.getAll(status)
            .then(response => {
                commit('setVouchers', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    getById({commit}, voucherId) {
        commit("setLoading", true)

        return VouchersApi.getById(voucherId)
            .then(response => {
                console.log("response : " + JSON.stringify(response))
                commit('setVoucher', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    add({commit}, voucher) {
        commit("setLoading", true)

        let updatedVoucher = {
            ...voucher,
            id: uuidv4()
        }
        return VouchersApi.add(updatedVoucher)
            .then(response => {
                commit('setVoucher', response)
                return response
            })
            .catch((e) => {
                switch (e) {
                    case 409:
                        throw "Voucher code déjà existant"
                    default:
                        throw "La création à échoué"
                }
            })
            .finally(() => commit("setLoading", false))
    },

    update({commit}, voucher) {
        commit("setLoading", true)

        return VouchersApi.update(voucher)
            .then(response => {
                commit('setVoucher', response)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    delete({commit}, voucherId) {
        commit("setLoading", true)

        return VouchersApi.delete(voucherId)
            .then(response => {
                commit('removeVoucher', voucherId)
                return response
            })
            .catch((e) => commit('setError', e))
            .finally(() => commit("setLoading", false))
    },

    validatePromotionCode(_, {code, userId}) {

        return VouchersApi.validateVoucher(code, userId).then(voucher => {
            if (voucher.valid) {
                return voucher
            } else {
                throw voucher.message
            }
        }).catch(e => {
            throw mapCartError(e)
        })
    },


}

function mapCartError(error) {

    if (typeof error === 'string' || error instanceof String) {
        return error
    }

    switch(error) {
        case 404:
            return "Code invalide"
        default:
            return 'Erreur technique, veuillez réessayer'

    }
}

const mutations = {

    setVouchers(state, vouchers) {

        state.vouchers = vouchers
    },

    setVoucher(state, voucher) {

        let index = state.vouchers.findIndex(o => o.id === voucher.id)
        if (index === -1) {
            state.vouchers.push(voucher)
        } else {
            state.vouchers[index] = voucher
        }
    },

    setError(state, error) {
        state.error = error
    },

    setLoading(state, status) {
        if (status) state.error = null
        state.loading = status
    },

    removeVoucher(state, voucherId) {

        let actualVouchers = state.vouchers
        _.remove(actualVouchers, v => v.id === voucherId)
        state.vouchers = actualVouchers
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}