<template>
  <div class="page">
    <div class="content-panel">
      <div :class="contentClass">
        <div class="vouchers-wrapper right-panel">

          <Block no-padded no-header no-footer>
            <div class="search-box">
              <v-text-field
                  class="filter-input"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  solo
                  flat
                  aria-autocomplete="none"
                  label="Rechercher une remise"
                  single-line
                  hide-details
              ></v-text-field>
            </div>
          </Block>


          <Block no-padded>
            <template slot="header-action">
              <v-btn v-if="!showEditPanel"
                     color="primary" text
                     @click="$router.push('/vouchers/create')">
                Ajouter un voucher
              </v-btn>
            </template>
            <v-data-table :items="vouchers"
                          :headers="headers"
                          :search="search"
                          v-model="selectedVouchers"
                          item-key="id"
                          show-select
                          no-data-text="Il n'y aucune remise"
                          @click:row="handleClick">
              <template v-slot:[`item.id`]="{ item }">
                {{ item.id.substring(0, 8) }}
              </template>
              <template v-slot:[`item.discount`]="{ item }">
                {{ `${item.discount} ${item.discountType ===  'PERCENT' ? '%' : '€'}` }}
              </template>

              <template v-slot:[`item.expirationDate`]="{ item }">
                {{
                  $formatDate(item.expirationDate, 'Do MMMM YYYY')
                }}
              </template>
            </v-data-table>
          </Block>
        </div>

        <div :class="editPanelClass">
          <router-view :key="$route.path" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import Block from "../components/SimpleBlock.vue";

export default {
  components: {Block},
  data() {
    return {
      search: null,
      selectedVouchers: [],
      fullHeaders: [
        {
          text: 'Code',
          value: 'code',
        },
        {text: 'Nom', value: 'description'},
        {text: 'Remise', value: 'discount'},
        {text: 'Expire le', value: 'expirationDate'},
        {text: 'Unique', value: 'uniquePerCustomer'}
      ],
      showEditPanel: false,
    }
  },
  computed: {
    ...mapState({
      vouchers(state) {
        return state.vouchers.vouchers
      }
    }),
    editPanelClass() {
      if (this.showEditPanel) {
        return "edit-panel open"
      } else return "edit-panel"
    },
    contentClass() {
      if (this.showEditPanel) {
        return "panel-content open"
      } else return "panel-content"
    },
    headers() {
      return this.showEditPanel
          ? this.fullHeaders.filter(h => ['code', 'discount', 'expirationDate', 'uniquePerCustomer'].indexOf(h.value) !== -1)
          : this.fullHeaders
    }
  },
  methods: {
    handleClick(value) {
      if (this.showEditPanel) {
        this.$router.replace(`/vouchers/${value.id}`);
      } else {
        this.$router.push(`/vouchers/${value.id}`);
      }
    },

  },
  async created() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;

  },
  updated() {
    this.showEditPanel = !!this.$route.params.id || this.$route.path.indexOf("create") !== -1;

  },
  async mounted() {
    await this.$store.dispatch("vouchers/getAll")
  }
}
</script>
<style lang="scss" scoped>

@import "src/styles/ContentPanel.scss";

.vouchers-wrapper {
  flex: 1;
}


@media only screen and (max-width: 1000px) {


}
</style>
