import axios from 'axios'
import OrderApi from './OrderApi'
import UserApi from './UserApi'
import VouchersApi from "./VouchersApi";
import ReturnsApi from "./ReturnsApi";
import HealthService from "./health-service";
import ShopApi from './ShopApi'

axios.defaults.baseURL =  process.env.VUE_APP_BACKEND_HOST;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(function(request) {

    if (request.baseURL === process.env.VUE_APP_BACKEND_HOST) {
        request.headers = Object.assign(request.headers, {
            'X-API-KEY': process.env.VUE_APP_MPA_API_KEY
        })
    }

    return request
}, function(error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export {
    OrderApi,
    UserApi,
    VouchersApi,
    ReturnsApi,
    HealthService,
    ShopApi
}
