<template>
  <span :class="tagClass">{{ getName(status) }}</span>
</template>

<script>
export default {
  props: {
    value: String,
    large: Boolean,
    xLarge: Boolean,
    item: Boolean
  },
  data() {
    return {
      status: this.value,
      tagsClass: {
        CREATED: {
          tagClass: "tag orange"
        },
        VALIDATED: {
          tagClass: "tag green"
        },
        DECLINED: {
          tagClass: "tag red"
        },
        CHECKING: {
          tagClass: "tag orange"
        },
        TERMINATED: {
          tagClass: "tag green"
        },
        IN_PROGRESS: {
          tagClass: "tag orange"
        }

      }
    }
  },
  computed: {
    tagClass() {
      let clazz= ""
      let tag = this.tagsClass[this.status];
      if (!tag) clazz = "tag red"
      else clazz = tag.tagClass

      if (this.large) clazz += " large"
      if (this.xLarge) clazz += " x-large"
      return clazz
    }
  },
  watch: {
    value(val) {
      this.status = val
    }
  },
  methods: {
    getName(status) {
      return this.item
          ? this.$store.getters['returns/getReturnItemStatusName'](status)
          : this.$store.getters['returns/getReturnStatusName'](status)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

.tag {
  font-weight: bold;
  font-size: medium;
  background-color: transparent !important;
}

.tag.large {
  font-size: large;
}

.tag.x-large {
  font-size: x-large;
}

.tag.red {
  color: $red;
}

.tag.green {
  color: $green;
}

.tag.yellow {
  color: $yellow;
}
.tag.orange {
  color: $orange;
}
</style>