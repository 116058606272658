<template>
  <div class="page page-content">
    <h1>Paramètres du magasin</h1>
    <ClosedDaysEditor :value="shop.closedDays" :loading="closedDaysLoading" @on-save="saveClosedDays"/>
    <OpeningHoursEditor :value="shop.openingHours" :loading="openingHoursLoading" @on-save="saveOpeningHours" :error="openingHoursError"/>
  </div>
</template>

<script>
//import Block from "../../components/Block";
import {mapState} from "vuex";
import ClosedDaysEditor from "./ClosedDaysEditor";
import OpeningHoursEditor from "@/views/settings/OpeningHoursEditor.vue";

export default {
  name: "SettingsPage",
  components: {OpeningHoursEditor, ClosedDaysEditor },
  data() {
    return {
      editClosingDay: false,
      closedDaysLoading: false,
      openingHoursLoading: false,
      openingHoursError: null
    }
  },
  computed: {
    ...mapState({
      shop(state) {
        return state.shops.shop ?? {}
      }
    })
  },
  methods: {
    async saveOpeningHours(openingHours) {
      let shop = JSON.parse(JSON.stringify(this.shop))
      shop.openingHours = openingHours

      try {
        this.openingHoursLoading = true
        await this.saveShop(shop)
      } catch (e) {
        this.openingHoursError = e
      } finally {
        this.openingHoursLoading = false
      }
    },
    async saveClosedDays(closeDays) {

      let shop = JSON.parse(JSON.stringify(this.shop))
      shop.closedDays = closeDays

      try {
        this.closedDaysLoading = true
        await this.saveShop(shop)
      } finally {
        this.closedDaysLoading = false
      }
    },
    async saveShop(shop) {
      return await this.$store.dispatch("shops/update", shop)
    }
  },
  created() {
    this.$store.dispatch("shops/loadShop");
  }
}
</script>

<style lang="scss" scoped>
.settings-block {
  margin: 1em 0;
}
</style>
