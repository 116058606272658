import { render, staticRenderFns } from "./StatsPage.vue?vue&type=template&id=48339ca3&scoped=true"
import script from "./StatsPage.vue?vue&type=script&lang=js"
export * from "./StatsPage.vue?vue&type=script&lang=js"
import style0 from "./StatsPage.vue?vue&type=style&index=0&id=48339ca3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../common/temp/node_modules/.pnpm/vue-loader@15.11.1_qty7iymlffgpukvncpluvycwfu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48339ca3",
  null
  
)

export default component.exports