<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TimePicker",
  props: {
    label: String,
    name: String,
    value: [String, Object],
    placeholder: String,
    readonly: Boolean,
    rules: Array,
    max: String,
    min: String,
    allowedMinutes: {
      type: Array,
      default: () => [0, 15, 30, 45]
    }
  },
  data() {
    return {
      show: false,
      insideValue: this.value
    }
  },
  watch: {
    value(val) {
      this.insideValue = val
    }
  },
  computed: {
    computedDateFormattedMoment(): String {

      if (this.insideValue) {
        const selectedTime = this.insideValue.toString();
        return selectedTime.length > 5 ? selectedTime.split(":").slice(0,-1).join(':') : selectedTime;
      } else {
        return ''
      }
    },
  },
  methods: {
    togglePicker(e: Event) {
      if (!this.readonly) {
        this.show = !this.show
      } else {
        e.preventDefault()
      }
    },
    update() {
      this.show = false
      this.$emit('on-update', {key: this.name, value: this.insideValue})
    },
    onClear() {
      this.insideValue = undefined
      this.$emit('on-update', {key: this.name, value: this.insideValue})
    }
  }
})
</script>

<template>
  <div class="field-container">

    <v-menu v-model="show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            :readonly="readonly"
            :label="label"
            :value="computedDateFormattedMoment"
            :placeholder="placeholder"
            :clearable="!readonly"
            @click:clear="onClear"
            v-bind="attrs"
            :rules="rules"
            v-on="on" />
      </template>
      <v-time-picker
          locale="fr"
          format="24hr"
          :max="max"
          :min="min"
          :readonly="readonly"
          v-model="insideValue"
          :allowed-minutes="allowedMinutes"
          @input="update">
      </v-time-picker>
    </v-menu>
  </div>
</template>

<style scoped lang="scss">

</style>