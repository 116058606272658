<template>
  <div class="return-edit-panel">
    <div class="header">
      <div style="display: flex; align-items: center">
        <v-icon class="menu-icon" @click="close()" size="48">mdi-chevron-left</v-icon>
        <h2>{{ pageName }}</h2>
      </div>
      <div class="actions-container">
        <v-progress-circular v-if="isLoading" size="24" width="3" indeterminate
                             style="margin-right: 1em"></v-progress-circular>

        <ReturnStatusTag :value="returnItem.status" />
        <v-menu bottom left offset-y close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="menu-icon">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in returnMenuItems" :key="`submenu-${i}`" @click="item.action()">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <Block title="info" expandable>

      <template v-slot:content-expanded>
        <v-textarea :value="returnItem.description" full-width  no-resize  label="Commentaires" readonly></v-textarea>

      </template>
        <div class="field-row">
          <v-text-field :value="returnItem.userId ? returnItem.userId.substring(0, 8).toUpperCase() : null"
                        style="max-width: 100px"
                        label="ID Client" readonly></v-text-field>
          <v-text-field :value="returnItem.clientName" label="Nom du client" readonly></v-text-field>
          <v-text-field :value="returnItem.clientPhone" label="Téléphone" readonly></v-text-field>
        </div>
        <DateField name="creationDate" label="Date de la demande"
                   placeholder="sélectionnez une date" readonly
                   :value="returnItem.creationDate" />

    </Block>

    <Block title="Articles">

      <div class="return-items-container">
        <return-item-cartouche :item="item.item" v-for="(item, i) in returnItem.items" v-bind:key="`item-${i}`"
                               @on-item-status-change="updateItemStatus"
                               :reason="item.reason"
                               :status="item.status"
                               :warranty="item.warranty"/>
      </div>
    </Block>

    <SimpleDialog title="Êtes vous sûr de vouloir supprimer cette demande de retour ?"
            :dialog="confirmDelete" @agreed="confirmDelete = false">
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn text @click="confirmDelete = false">Annuler</v-btn>
        <v-btn color="red" @click="deleteReturn">Supprimer</v-btn>
      </template>
    </SimpleDialog>

  </div>
</template>

<script>
import Block from "../components/SimpleBlock.vue";
import DateField from "../components/DateField";
import _ from "lodash";
import ReturnStatusTag from "../components/ReturnStatusTag";
import ReturnItemCartouche from "./ReturnItemCartouche";
import SimpleDialog from "../components/SimpleDialog.vue";

export default {

  components: {SimpleDialog, ReturnItemCartouche, ReturnStatusTag, Block, DateField},
  data() {
    return {
      returnItem: {},
      returnFormError: null,
      isLoading: false,
      confirmDelete: false,
      returnMenuItems: [{
        title: "Supprimer",
        action: () => this.deleteReturn()
      }]
    }
  },
  computed: {
    pageName() {
      return this.returnItem && this.returnItem.id ? `Demande de retour n° ${this.returnItem.id.substring(0, 8).toUpperCase()}` : "Nouveau voucher"
    },
    filteredReturnItems() {

      if (this.returnItem && this.returnItem.items.filter(i => i.reason === 'WARRANTY').length === 0) {
        return this.returnMenuItems.filter(i => i.title === "Demande d'accord de garantie")
      } else
        return this.returnMenuItems
    }
  },
  methods: {

    close() {
      this.$router.replace("/returns")
    },
    async updateItemStatus({reference, status, warrantyId}) {

      try {
        this.isLoading = true
        this.returnItem = await this.$store.dispatch("returns/updateItem", {
          id: this.returnItem.id,
          reference: reference,
          status: status,
          warrantyId: warrantyId
        })
      } catch (e) {
        this.returnFormError = e
      } finally {
        this.isLoading = false
      }
    },
    deleteReturn() {

      if (this.confirmDelete) {
        this.isLoading = true
        this.$store.dispatch("returns/delete", this.returnItem.id).then(() => {
          this.$router.replace("/returns")
        }).finally(() => this.isLoading = false)
      } else {
        this.confirmDelete = true
      }

    },
    async fetchItem() {
      let id = this.$route.params.id
      if (_.isEmpty(this.returnItem) || this.returnItem.id !== id) {

        let existingReturn = this.$store.getters["returns/getById"](id)
        if (existingReturn) this.returnItem = existingReturn

        try {
          this.isLoading = true
          let returnItem = await this.$store.dispatch("returns/getById", id);
          if (returnItem) {
            this.returnItem = Object.assign({}, returnItem)
          }
        } catch (e) {
          this.error = e
        } finally {
          console.log("hey")
          this.isLoading = false
        }

      }
    },
  },
  created() {
    this.fetchItem()
  },
  updated() {
    this.fetchItem()
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/ContentPanel";

.small {
  max-width: 200px;
}

.return-items-container {
  display: flex; flex-wrap: wrap;
}

</style>