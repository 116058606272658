<template>
  <div class="page page-content" style="text-align: center">
    <Block style="padding: 60px 90px 60px; max-width: 550px; width: 550px; margin: auto">
      <h1>Vous n'êtes pas autorisé à accéder à cette page</h1>
      <p>Si vous pensez que c'est une erreur, contactez l'administrateur de ce site.</p>
    </Block>
  </div>
</template>
<script>
import Block from "../components/SimpleBlock.vue";

export default {
components: {
  Block
}
}
</script>