<template>
  <div class="product-container">

    <div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 0.5em">
      <div><strong>{{productName}}</strong></div>
      <v-menu bottom left offset-y close-on-content-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="menu-icon">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, i) in returnMenuItems" :key="`submenu-${i}`" @click="item.action()">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </div>
    <div class="product">
      <div class="image">
        <v-img contain :src="item.product.imgUrl"></v-img>
      </div>
      <div class="product-info">
        <div class="product-info-line">
          <div>Prix unitaire</div>
          <div>{{`${itemPrice} €`}}</div>
        </div>
        <div v-if="item.product.priceTTCDeposit > 0" class="product-info-line">
          <div>Consigne</div>
          <div>{{`${item.product.priceTTCDeposit} €`}}</div>
        </div>
        <div class="product-info-line">
          <div>Quantité</div>
          <div>{{item.quantity}}</div>
        </div>
        <div class="product-info-line">
          <div>Prix total</div>
          <div>{{`${totalPrice} €`}}</div>
        </div>
      </div>
    </div>

    <div style="width: 100%; margin-top: 1em">
     <div>Motif de retour : <return-reason-tag :value="reason" /></div>
      <div>Status: <return-status-tag :value="status" item /></div>
      <div>Voiture :
        <ul v-if="item.product.carIdentification">
          <li v-for="(ids, i) in Object.keys(item.product.carIdentification)" v-bind:key="`car-id-${i}-${item.product.reference}`">
            {{`${ids} : `}}{{item.product.carIdentification[ids]}}
          </li>
        </ul>
      </div>
      <div v-if="warranty">Donnnée de garantie
        <ul>
          <li>N° garantie fournisseur : {{warranty.warrantyId}}</li>
          <li>{{warranty.professionalAssembly ? `Pièce montée par un professionnel` : `Pièce montée par un particulier`}}</li>
          <li>Immat : {{warranty.platenumber}}</li>
          <li>Mise en circulation : {{$formatDate(warranty.preRegistrationDate, 'DD/MM/YYYY')}}</li>
          <li>N° chassis : {{warranty.carFrameNumber}}</li>
          <li>Modèle : {{warranty.brand}} {{warranty.model}}</li>
          <li>Cylindrée : {{warranty.engineSize}}</li>
          <li>Date de montage : {{$formatDate(warranty.assemblyDate, 'DD/MM/YYYY')}}</li>
          <li>Kilométrage au montage : {{warranty.startMileage}} km</li>
          <li>Date de démontage : {{$formatDate(warranty.disassemblyDate, 'DD/MM/YYYY')}}</li>
          <li>Kilométrage au démontage : {{warranty.endMileage}} km</li>
        </ul>
        </div>
    </div>

    <SimpleDialog title="Ajouter le numéro de garantie" :dialog="showWarrantyReturnDialog">

      <template v-slot:content>
        <v-form ref="voucherUserForm">
          <v-text-field label="Numéro de garantie" v-model="warrantyReturnId" :rules="required"></v-text-field>
        </v-form>
      </template>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showWarrantyReturnDialog = false">Annuler</v-btn>
        <v-btn color="primary" @click="askWarranty">OK</v-btn>
      </template>
    </SimpleDialog>
  </div>
</template>

<script>
import ReturnReasonTag from "../components/ReturnReasonTag.vue";
import SimpleDialog from "../components/SimpleDialog.vue";
import ReturnStatusTag from "../components/ReturnStatusTag";
export default {
  components: {ReturnStatusTag, ReturnReasonTag, SimpleDialog},
  props: {
    item: Object,
    reason: String,
    warranty: Object,
    mode: String,
    status: String
  },
  data() {
    return {
      warrantyReturnId: this.warranty ? this.warranty.warrantyId : null,
      showWarrantyReturnDialog: false,
      required: [
        value => !!value || "Champ obligatoire",
      ],
      allReturnMenuItems: [{
        key: "warranty",
        title: "Editer n° garantie fournisseur",
        action: () => this.openWarrantyDialog()
      },{
        key: "ok",
        title: "Valider la demande",
        action: () => this.validateReturn()
      },{
        key: "ko",
        title: "Refuser la demande",
        action: () => this.declineReturn()
      }]
    }
  },
  computed: {
    returnMenuItems() {

      if (this.reason === 'WARRANTY') {

        return this.warranty.warrantyId
            ?  this.status === 'VALIDATED' || this.status === 'DECLINED' ? this.allReturnMenuItems.filter(m => m.key !== "warranty") : this.allReturnMenuItems
            : this.allReturnMenuItems.filter(m => m.key !== 'ok')


      } else {

        return this.allReturnMenuItems.filter(m => m.key !== "warranty")
      }

    },
    productName() {
      let name = this.item.product.productName

      if (this.item.product.sellQuantity > 1) {
        return name.replace("(x1)", "") + ` (lot de ${this.item.product.sellQuantity})`
      } else {
        return name
      }
    },
    itemPrice() {
      return this.item.product.price * this.item.product.sellQuantity
    },
    totalPrice() {
      return (this.itemPrice + this.item.product.priceTTCDeposit) * this.item.quantity
    },
  },
  methods: {
    validateReturn() {
      this.$emit("on-item-status-change", {
        reference: this.item.product.supplierReference,
        status: "VALIDATED"
      })

    },
    declineReturn() {
      this.$emit("on-item-status-change", {
        reference: this.item.product.supplierReference,
        status: "DECLINED"
      })
    },
    openWarrantyDialog() {
      this.showWarrantyReturnDialog = true
    },
    askWarranty() {

      if (this.$refs.voucherUserForm?.validate()) {

        this.$emit("on-item-status-change", {
          reference: this.item.product.supplierReference,
          status: "CHECKING",
          warrantyId: this.warrantyReturnId
        })
        this.showWarrantyReturnDialog = false
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/colors";

.product-container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 2em);
  margin-right: 2em;
  margin-bottom: 2em;
  padding: 1em;
  border: 1px $border-color solid;
  border-radius: 1rem;

  .product {
    display: flex;
    margin-bottom: 0.5em;
    .image {
      flex: 0.4;
      max-width: 200px;
    }

    .product-info {

      font-size: medium;
      padding-left: 1em;
      flex: 0.6;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .product-info-line {
        display: flex;
        justify-content: space-between;

        div:last-child {
          margin-left: 0.5em;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {

  .product-container {
    width: auto;
    flex: 1;

    .product {
      flex-direction: column;
      .image {
        max-width: 300px;
        align-self: center;
        margin: 0.5em 0;
      }
    }
  }
}
</style>
