<script lang="ts">
import type {PropType} from 'vue'
import {defineComponent} from 'vue'
import {OrderItem} from 'shared-model'
import _ from 'lodash'

function groupItems(input: OrderItem[]): OrderItem[] {
  let roughItems = JSON.parse(JSON.stringify(input)) as OrderItem[];
  let dictionary = _.groupBy(roughItems, (item) => item.product.reference);
  const groupedItems = []
  for (let key in dictionary) {
    let items = dictionary[key]
    let quantity = items.reduce((acc, item) => acc + item.quantity, 0)
    groupedItems.push({
      product: items[0].product,
      quantity
    })
  }
  return groupedItems
}

export default defineComponent({
  name: "RefundOrderItemCartouche",
  props: {
    items: {
      type: Array as PropType<OrderItem[]>,
      required: true,
    },
  },
  data() {
    return {
      selectedItems: [] as OrderItem[],
      groupedItems: groupItems(this.items) as OrderItem[]
    }
  },
  computed: {

  },
  methods: {
    editReturnQuantity(item: OrderItem, qty: number) {
      const index = this.selectedItems.findIndex(i => i.product.reference === item.product.reference)
      if (index !== -1) {
        this.selectedItems[index].quantity = qty
      }
    },
    onSelectItem(item: OrderItem, selected: boolean) {

      if (selected) {
        this.selectedItems.push(JSON.parse(JSON.stringify(item)))
      } else {
        this.selectedItems = this.selectedItems.filter(i => i.product.reference !== item.product.reference)
      }
    },
    quantities(item: OrderItem) {
      let orderItems = groupItems(this.items);
      const index = orderItems.findIndex(i => item.product.reference = i.product.reference)
      if (index != -1) {
        let iterable = Array(item.quantity).keys();
        return Array.from(iterable).map(n => n + 1)
      }

      return 1
    },
    productName(item: OrderItem) {
      return `${this.$productName(item.product)} (x${item.quantity})`
    },
    unitPriceTTCWithDeposit(item: OrderItem) {
      return `${this.$unitPriceTTCWithDeposit(item.product).toFixed(2)} €`
    },

  },
  watch: {
    selectedItems(val) {
      this.$emit("on-update", val)
    },
  },
})
</script>

<template>
  <v-list>
    <v-list-item v-for="(item, i) in groupedItems" v-bind:key="`${item.product.reference}${i}`">
      <template v-slot:default>

        <v-list-item-content>
          <v-list-item-title>{{ productName(item) }}</v-list-item-title>
          <v-list-item-subtitle>{{ `${unitPriceTTCWithDeposit(item)} l'unité` }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="item.quantity > 1">
          <v-select class="quantity-select" label="quantité"
                    :items="quantities(item)"
                    :value="item.quantity"
                    @change="editReturnQuantity(item, $event)"></v-select>
        </v-list-item-action>

        <v-list-item-action>
          <v-checkbox @change="onSelectItem(item, $event)"></v-checkbox>
        </v-list-item-action>
      </template>
    </v-list-item>
  </v-list>
</template>

<style scoped lang="scss">

</style>