<template>
  <div class="order-edit-panel">
    <OrderHeader :item="order" :loading="loading" :error="error" @on-order-action="executeOrderAction" />

    <Block v-if="order.message || editMessage" title="message">

      <div v-if="editMessage">
        <v-textarea v-model="order.message"></v-textarea>
      </div>
      <div class="message-container" v-else>{{ order.message }}</div>

      <template v-if="editMessage === false" v-slot:header-action>
        <v-icon @click="editMessage = true">mdi-pencil</v-icon>
      </template>

      <template v-if="editMessage" v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="editMessage = false">Annuler</v-btn>
        <v-btn color="primary" elevation="0" @click="saveOrderMessage">Sauvegarder</v-btn>
      </template>
    </Block>

    <Block title="Info commande">

      <div class="field-row">
        <v-text-field v-model="order.deliveryType" label="Type" readonly></v-text-field>
        <v-text-field v-model="order.externalId" label="Référence WinPro" readonly></v-text-field>
        <v-text-field v-model="order.externalInvoiceId" label="Référence Facture WinPro" readonly></v-text-field>
      </div>
      <div class="field-row">
        <v-text-field v-model="user.firstname" label="Prénom client" readonly></v-text-field>
        <v-text-field v-model="user.lastname" label="Nom client" readonly></v-text-field>
        <v-text-field :value="user.userId ? user.userId.substring(0, 8).toUpperCase() : null" label="Id client"
                      readonly></v-text-field>
      </div>
      <div class="field-row">
        <v-text-field v-model="user.email" label="Email client" readonly></v-text-field>
        <v-text-field v-model="user.phone" label="Téléphone client" readonly></v-text-field>
        <v-text-field v-model="order.phone" label="Téléphone commande" readonly></v-text-field>
      </div>
      <v-text-field :value="$formatDate(order.creationDate, 'Do MMMM YYYY HH:mm')" label="Date de la commande"
                    readonly></v-text-field>

    </Block>

    <Block title="Descriptif de la commande">

      <v-data-table :items="order.items"
                    :headers="headers"
                    no-data-text="Aucun produit dans la commande">

        <template v-slot:[`item.productName`]="{ item }">
          {{ computeProductName(item.product)}}
        </template>
        <template v-slot:[`item.reference`]="{ item }">
          {{ item.product.reference }}
        </template>
        <template v-slot:[`item.unitPrice`]="{ item }">
          {{ `${$unitPriceTTCWithoutDeposit(item.product, item.quantity).toFixed(2)} €` }}
          <span>{{depositText(item)}}</span>
        </template>
        <template v-slot:[`item.nbArticles`]="{ item }">
          {{ item.quantity }}
        </template>
        <template v-slot:[`item.availabilityDate`]="{ item }">
          {{ $formatDate(item.product.availabilityDate, 'DD/MM/YY à HH:MM') }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ `${$totalPriceTTCWithDeposit(item.product, item.quantity).toFixed(2)} €` }}
        </template>
      </v-data-table>

    </Block>

    <Block title="Info de livraison" v-if="order.deliveryType !== 'DRIVE'">
      <div class="field-row">
        <Field label="Service">{{order.deliveryType}}</Field>
        <Field label="Numéro tracking">{{order.delivery.trackingNumber}}</Field>
        <Field label="Id etiquette transport">{{order.delivery.reservationNumber}}</Field>
        </div>
      <div class="field-row">
        <Field label="Date d'expédition prévue'">
          {{$formatDate(order.delivery.expeditionDate, 'DD/MM/YYYY à HH:mm')}}
        </Field>
        <Field label="Date de livraison pévue">
          {{$formatDate(order.delivery.deliveryDate, 'DD/MM/YYYY à HH:mm')}}
        </Field>
      </div>

      <Field label="Adresse de livraison">
        <div class="address-container">
          <div class="name">{{order.delivery.relay.name}}</div>
          <div class="street">{{order.delivery.relay.address1}}</div>
          <div v-if="order.delivery.relay.address2" class="street">{{order.delivery.relay.address2}}</div>
          <div v-if="order.delivery.relay.address3" class="street">{{order.delivery.relay.address3}}</div>
          <div class="city">{{`${order.delivery.relay.zipcode} ${order.delivery.relay.city}`}}</div>
        </div>
      </Field>

      <v-simple-table>
        <template v-slot:default v-if="trackingEvents.length > 0">
          <thead>
          <tr>
            <th class="text-left">Message</th>
            <th class="text-left">Date</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in trackingEvents" :key="item.code">
            <td>{{ item.message }}</td>
            <td>{{ $formatDate(item.eventDate, "dd//mm/yyyy hh:mm") }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </Block>

    <Block v-if="order.paymentInfo" title="Info de paiement">

      <div class="field-row ">
        <v-text-field v-model="order.paymentInfo.provider" label="Provider" readonly></v-text-field>
        <v-text-field v-model="order.paymentInfo.transactionId" label="Transaction Id" readonly></v-text-field>
      </div>
      <div class="field-row">
        <v-text-field v-model="order.paymentInfo.status" label="Status" readonly></v-text-field>
        <v-text-field v-model="order.paymentInfo.totalRefunded" label="Montant remboursé" readonly></v-text-field>
      </div>

      <div class="field-row">
        <v-text-field :value="totalHT" label="Total HT" readonly></v-text-field>
        <v-text-field v-if="usedVoucher" v-model="usedVoucher.code" label="Code du voucher utilisé"></v-text-field>
        <v-text-field v-model="totalDiscount" label="Total discount" readonly></v-text-field>
        <v-text-field v-model="totalTVA" label="Total TVA" readonly></v-text-field>
        <v-text-field v-model="totalDeposit" label="Total Consigne" readonly></v-text-field>
        <v-text-field v-model="totalPaid" label="Total TTC" readonly>
        </v-text-field>

      </div>

    </Block>

    <Block title="Info de facturation">
      <div class="field-row">
        <Field label="Adresse de facturation">
          <v-skeleton-loader v-if="!order || !order.billingAddress" type="article"></v-skeleton-loader>
          <div v-else class="address-container">
            <div class="name">{{`${order.billingAddress.gender}. ${order.billingAddress.recipient}`}}</div>
            <div class="street">{{order.billingAddress.street}}</div>
            <div class="city">{{`${order.billingAddress.zipcode} ${order.billingAddress.city}`}}</div>
            <div class="country">{{order.billingAddress.countryIso3Code}}</div>
          </div>
        </Field>
        <Field label="Téléphone">
          {{order.phone}}
        </Field>
      </div>
    </Block>

    <OrderErrorDialog :order="order"
                      :show-dialog="displayErrorMessageDialog"
                      @on-cancel="displayErrorMessageDialog = false"
                      @on-success="onErrorMessageSaved"/>

    <RefundOrderDialog :order="order"
                       :show-dialog="showRefundDialog"
                       :show-refund-alert="showRefundAlert"
                       @on-cancel="showRefundDialog = false"
                       @on-success="refundOrderSuccess"/>

    <SimpleDialog title="Générer un voucher" :dialog="showUserVoucherDialog">

      <template v-slot:content>
        <v-form ref="voucherUserForm">
          <v-text-field v-model="userVoucher.code" @input="userVoucher.code" label="Voucher code"
                        :rules="[required]" autocomplete="off" readonly></v-text-field>

          <div class="field-row">
            <v-select :items="$store.state.vouchers.discountTypes" item-text="value" item-value="key"
                      label="Type de remise"
                      :rules="[required]" v-model="userVoucher.discountType"></v-select>

            <v-text-field autocomplete="off" v-model="userVoucher.discount"
                          :rules="userVoucher.discountType === 'PERCENT' ? [required, max100] : [required]" type="number" label="Valeur de la remise"></v-text-field>
          </div>

          <DateField name="expirationDate" label="Date d'expiration"
                     placeholder="sélectionnez une date"
                     :value="userVoucher.expirationDate" @on-update="update"/>
        </v-form>
      </template>


      <template v-slot:actions>
        <div v-if="saveVoucherError" class="error--text">{{ saveVoucherError }}</div>
        <v-spacer></v-spacer>
        <v-btn text @click="showUserVoucherDialog = false">Annuler</v-btn>
        <v-btn color="primary" elevation="0" :loading="loading" @click="saveUserVoucher">Sauvegarder</v-btn>
      </template>
    </SimpleDialog>

    <SimpleDialog title="Confirmation de suppression" :dialog="confirmOrderDeletionDialog">
      <template v-slot:content>
        Êtes vous sur de vouloir supprimer cette commande ? Cette action est définitive !
      </template>
      <template v-slot:actions>
        <div v-if="error" class="error--text">{{ error }}</div>
        <v-spacer></v-spacer>
        <v-btn text @click="confirmOrderDeletionDialog = false">Annuler</v-btn>
        <v-btn color="red" elevation="0" :loading="loading" @click="deleteOrder()">Supprimer</v-btn>
      </template>
    </SimpleDialog>

    <SimpleDialog :title="confirmationMetadata.title" :dialog="displayConfirmation">
      <template v-slot:content>
        <div>{{confirmationMetadata.text}}</div>
      </template>
      <template v-slot:actions>
        <div v-if="error" class="error--text">{{ error }}</div>
        <v-spacer></v-spacer>
        <v-btn text @click="displayConfirmation = false">Annuler</v-btn>
        <v-btn color="red" elevation="0" :loading="loading" @click="confirmationMetadata.onClick">Ok</v-btn>
      </template>
    </SimpleDialog>

    <SimpleDialog title="Ajouter les infos de paiement" :dialog="displayPaymentInfoDialog">

      <template v-slot:content>
        <span>Work in progress</span>

        <v-form ref="paymentInfoForm">
          <v-select label="Mode de paiement"
                    :value="paymentInfo.provider"
                    item-text="label"
                    item-value="key"
                    @input="updatePaymentInfo('provider', $event)"
                    :items="paymentProviders"
                    :rules="[required]">
          </v-select>
          <v-text-field v-model="paymentInfo.transactionId" label="Transaction id"
                        autocomplete="off" :rules="[required]"></v-text-field>

          <div style="display: flex; align-items: center;">
            <v-text-field v-model="promotionCode"
                          autocomplete="off"
                          ref="promotionCode"
                          :error-messages="promoError"
                          @change="promoError = null"
                          :readonly="discount && discount.valid"
                          label="Ajouter un code promo"
                          style="margin-right: 0.5em">
              <template v-slot:message>
                {{ promoError }}
              </template>
            </v-text-field>
            <v-btn v-if="!discount" color="primary" text elevation="0" @click="validatePromotionCode">Appliquer</v-btn>
            <v-btn v-else text @click="cancelPromotionCode">Annuler</v-btn>
          </div>


          <div class="field-row">
            <v-text-field :value="totalHT" label="Total HT" readonly></v-text-field>
            <v-text-field :value="totalDiscount" label="Total discount" readonly></v-text-field>
            <v-text-field :value="totalPaid" label="Total payé" readonly></v-text-field>
          </div>
        </v-form>
      </template>
      <template v-slot:actions>
        <div v-if="error" class="error--text">{{ error }}</div>
        <v-spacer></v-spacer>
        <v-btn text @click="displayPaymentInfoDialog = false">Annuler</v-btn>
        <v-btn color="primary" elevation="0" :loading="loading" @click="savePaymentInfo">Ajouter</v-btn>
      </template>
    </SimpleDialog>
  </div>
</template>

<script>
import _ from 'lodash'
import Block from "../../components/SimpleBlock.vue";
import SimpleDialog from "../../components/SimpleDialog.vue";
import DateField from "../../components/DateField.vue";
import Vue from "vue";
import {v4 as uuidv4} from 'uuid';
import moment from "moment";
import Field from "../../components/DisplayField.vue";
import OrderErrorDialog from "@/views/orders/components/OrderErrorDialog.vue";
import OrderHeader from "@/views/orders/components/OrderHeader.vue";
import RefundOrderDialog from "@/views/orders/components/RefundOrderDialog.vue";

export default {

  components: {RefundOrderDialog, OrderHeader, OrderErrorDialog, Block, SimpleDialog, DateField, Field},
  data() {
    return {
      displayErrorMessageDialog: false,
      showUserVoucherDialog: false,
      saveVoucherError: null,
      orderErrorMessage: null,
      error: null,
      order: {},
      trackingEvents: [],
      usedVoucher: null,
      user: {},
      editMessage: false,
      userVoucher: {
        code: uuidv4().substring(0, 8).toUpperCase(),
        description: "Voucher personnel",
        expirationDate: moment().add(1, 'y'),
      },
      headers: [
        {text: 'Label', value: 'productName'},
        {text: 'Référence', value: 'reference'},
        {text: 'Prix unitaire', value: 'unitPrice'},
        {text: "Nb articles", value: 'nbArticles'},
        {text: "Dispo", value: "availabilityDate"},
        {text: "Prix TTC", value: 'price'},
      ],
      required: value => !!value || "Champ obligatoire",
      max100: value => value <= 100 || "max 100 %",
      loading: false,
      confirmOrderDeletionDialog: false,
      displayPaymentInfoDialog: false,
      paymentInfo: {},
      promotionCode: null,
      discount: null,
      paymentProviders: [{
        key: 'PAYPAL',
        label: "PAYPAL"
      }, {
        key: 'CB_DESK',
        label: 'CB Comptoir (WIP)'
      }],
      promoError: null,
      showRefundAlert: false,
      displayConfirmation: false,
      confirmationMetadata: {},
      showRefundDialog: false
    }
  },
  computed: {
    totalHT() {
      return this.order && this.order.paymentInfo
          ? this.order.paymentInfo.totalHTAfterDiscount.toFixed(2)
          : null
    },
    totalDeposit() {
      let productsDeposits = this.order.items.filter(i => i.product.priceTTCDeposit > 0)
      return _.reduce(productsDeposits, (a, b) => {
        return a === 0
            ? b.product.priceTTCDeposit * b.quantity
            : a + b.product.priceTTCDeposit * b.quantity
      }, 0);
    },
    totalTVA() {
      return this.order && this.order.paymentInfo
          ? this.order.paymentInfo.totalTVA.toFixed(2)
          : null
    },
    totalPaid() {
      return this.order && this.order.paymentInfo
          ? this.order.paymentInfo.totalTTC.toFixed(2)
          : null
    },
    totalDiscount() {
      return this.order && this.order.paymentInfo
          ? this.order.paymentInfo.totalDiscount.toFixed(2)
          : null
    },
  },
  methods: {
    depositText(item) {
      return item.product.priceTTCDeposit ? `(${item.product.priceTTCDeposit.toFixed(2)} € consigne)` : null
    },
    computeProductName(product) {
      return this.$productName(product)
    },
    update({key, val}) {
      Vue.set(this.userVoucher, key, val)
    },
    updatePaymentInfo(key, val) {
      Vue.set(this.paymentInfo, key, val)
    },
    validatePromotionCode() {
      this.promoError = null
      this.$store.dispatch("vouchers/validatePromotionCode", {
        code: this.promotionCode,
        userId: this.order.userId
      }).then((discount) => {
        this.discount = discount
      }).catch(err => {
        this.promoError = err
      });
    },
    cancelPromotionCode() {
      this.promoError = null
      this.promotionCode = null
      this.discount = null
    },
    async deleteOrder() {
      try {

        this.error = null
        if (!this.confirmOrderDeletionDialog) {
          this.confirmOrderDeletionDialog = true
        } else {
          this.loading = true
          this.confirmOrderDeletionDialog = false
          await this.$store.dispatch("orders/deleteOrder", this.order)
          await this.$router.replace("/orders")
        }
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
      }
    },
    async createWinProOrder() {

      try {
        this.error = null
        this.loading = true
        let response = await this.$store.dispatch("orders/addWinProOrder", this.order)
        this.order = Object.assign({}, response)
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
      }
    },
    generateVoucher() {
      this.showUserVoucherDialog = true
    },
    saveUserVoucher() {

      this.saveVoucherError = null
      if (this.$refs.voucherUserForm.validate()) {
        this.loading = true
        this.$store.dispatch("vouchers/add", {
          ...this.userVoucher,
          uniquePerCustomer: this.userVoucher.discountType !== 'WALLET',
          userId: this.order.userId
        }).then(() => {
          this.showUserVoucherDialog = false
        }).catch((e) => {
          this.saveVoucherError = e
        }).finally(() => this.loading = false)
      }
    },
    savePaymentInfo() {
      this.promoError = null
      let validate = this.$refs.paymentInfoForm.validate();
      if (validate) {

        this.order = Object.assign({}, this.order, {
          paymentInfo:  {
            provider: this.paymentInfo.provider,
            totalHT: this.totalOrderItem,
            totalHTAfterDiscount: "",
            totalDiscount: this.totalDiscount,
            totalTTC: this.totalPaid,
            totalTVA: "",
            status: "COMPLETED",
            transactionId: this.paymentInfo.transactionId
          }
        })


        this.displayPaymentInfoDialog = false
        this.executeOrderAction('VALIDATE_PAYMENT')
      }
    },
    async printTransportLabel() {

      this.loading = true
      try {
        await this.$store.dispatch("orders/printTransportLabel", {
          orderId: this.order.orderId,
          userId: this.order.userId
        })
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
      }
    },
    onErrorMessageSaved(updatedOrder) {
      Vue.set(this, 'order', updatedOrder)
      this.displayErrorMessageDialog = false
    },
    saveOrderMessage() {
      this.loading = true
      this.$store.dispatch("orders/saveMessage", {
        orderId: this.order.orderId,
        userId: this.order.userId,
        message: this.order.message
      }).then(() => {
        this.editMessage = false
      }).catch(message => {
        this.editMessageError = message
      }).finally(() => this.loading = false)
    },
    executeOrderAction(action) {

      this.error = null

      if (action === 'DELETE_ORDER') this.deleteOrder()
      else if (action === 'CREATE_WINPRO_ORDER') this.createWinProOrder()
      else if (action === 'REFUND_ORDER') this.showRefundDialog = true
      else if (action === 'PRINT_TRANSPORT_LABEL') this.printTransportLabel()
      else if (action === 'GENERATE_VOUCHER') this.generateVoucher()
      else if (action === 'ORDER_IN_ERROR') this.displayErrorMessageDialog = true
      else if (action === 'VALIDATE_PAYMENT' && (!this.order.paymentInfo || this.order.paymentInfo.status === 'NOT_PAID')  ) {

        this.displayPaymentInfoDialog = true

      } else if (action === 'CANCEL_TRANSPORT_LABEL' && !this.displayConfirmation) {

        this.displayConfirmation = true
        this.confirmationMetadata = {
          title: "Annulation étiquette de transport",
          text: "Etes vous sur de vouloir annuler l'étiquette de transport associée à cette commande ?",
          onClick: () => this.executeOrderAction(action)
        }

      } else {

        this.loading = true
        this.error = null
        this.$store.dispatch("orders/changeStatus", {
          action: action,
          payload: [{
            orderId: this.order.orderId,
            userId: this.order.userId,
            message: this.orderErrorMessage,
            paymentInfo: this.order.paymentInfo,
            usedVoucher: this.discount ? this.discount.voucherId : null,
          }]
        }).then((response) => {

          if (response.error) {
            this.error = response.errorCode
          } else {
            this.displayConfirmation = false
            this.confirmationMetadata = {}
            this.displayErrorMessageDialog = false
            this.orderErrorMessage = null
            Vue.set(this, 'order', response.order)
          }
        }).catch(err => {
          this.error = err
        }).finally(() => this.loading = false)
      }
    },
    async fetchItem() {
      let id = this.$route.params.id
      let userId = this.$route.params.userId
      if (_.isEmpty(this.order) || this.order.orderId !== id) {

        this.loading = true
        let existingOrder = this.$store.getters["orders/getOrderById"](id)
        if (existingOrder) this.order = existingOrder

        try {

          let order = await this.$store.dispatch("orders/getOrderById", {
            userId: userId,
            orderId: id
          });

          if (order.deliveryType !== 'DRIVE') {
            this.trackingEvents = order.delivery?.trackingEvents ?? []
          }

          if (order.usedVoucher) {
            this.usedVoucher = await this.$store.dispatch("vouchers/getById", order.usedVoucher);
          }

          this.order = Object.assign({}, order)
          this.user = await this.$store.dispatch("users/getUserInfo", this.order.userId)

        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }

      }
    },
    refundOrderSuccess(updatedOrder) {
      this.showRefundDialog = false
      this.order = Object.assign({}, updatedOrder)
    }
  },
  created() {
    this.fetchItem()
  },
  updated() {
    this.fetchItem()
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/ContentPanel";

.message-container {
  white-space: pre-wrap;
}

.warning-icons {
  margin-left: 0.3em;
  font-size: 36px;
  color: $red
}

</style>
