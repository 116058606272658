<template>
    <div class="field-container">

        <v-menu v-model="show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                readonly
                :disabled="readonly"
                @click="togglePicker"
                :label="label"
                :value="computedDateFormattedMoment"
                :placeholder="placeholder"
                clearable
                @click:clear="onClear"
                v-bind="attrs"
                v-on="on" />
          </template>
          <v-date-picker
              v-model="insideValue"
              no-title
              locale="fr"
              @input="update">
          </v-date-picker>
        </v-menu>
    </div>
</template>

<script>

    export default {
        name: 'DateField',
        data() {
          return {
              show: false,
              insideValue: this.value
          }
        },
      watch: {
          value(val) {
            this.insideValue = val
          }
      },
        props: {
            label: String,
            name: String,
            value: [String, Object],
            placeholder: String,
          readonly: Boolean,
        },
      computed: {
        computedDateFormattedMoment () {
          return this.insideValue ? this.$formatDate(this.insideValue, 'Do MMMM YYYY') : ''
        },
      },
        methods: {
            togglePicker(e) {
                if (!this.readonly) {
                  this.show = !this.show
                } else {
                  e.preventDefault()
                }
            },
            update() {
                this.show = false
                this.$emit('on-update', {key: this.name, value: this.insideValue})
            },
          onClear() {
              this.insideValue = null
            this.$emit('on-update', {key: this.name, value: this.insideValue})
          }
        }
    }
</script>
